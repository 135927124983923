import React from "react";
import ReactDOM from "react-dom";
import "../assets/css/style.css"
export default function MailTo({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
}

