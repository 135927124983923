import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Brand1 from '../element/brand1';
import HeaderAr from '../layout/header-ar';
const aboutbg = require('./../../assets/images/background/image-11.jpg');



class AboutAr extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }

    render() {
        return (
            <>
                <HeaderAr />
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>


                <section class="page-banner">
                    <div className="page-banner-bg " style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>عنا  </h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>الصفحة الرئيسية </Link></li>
                            <li class="active">عنا </li>
                        </ul>
                    </div>

                </section>


                {/* About Section Two */}
                <section class="about-section-two" dir='rtl'>
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 ">
                                <div class="image-wrapper" >
                                    <div class="image-one">
                                        <img src={require('../../assets/images/resource/hassan.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 text-right">
                                <div class="content-box ">
                                    <div class="sec-title">
                                        <div class="sub-title sub-ar"  >عنا</div>
                                        <h2>تطوير استراتيجيات<br />قيم . نتائج </h2>
                                        <div class="text">تأسست شركة مصاروة للاستشارات في عام 1995 لتزويد الشركات من جميع المجالات وفي جميع مراحل الحياة بالمساعدة في إنشاء العمليات التجارية المناسبة للأعمال. يتمتع فريقنا بعقود من الخبرة ومتخصص في جميع أنواع الأعمال ، التكنولوجيا، الصناعة التحويلية ،
                                            الأغذية ، والمنصات الرقمية ، إلخ</div>
                                        <div class="text">على مر السنين ، تعلمنا أنه ليس كل صاحب عمل يمتلك جميع الأدوات اللازمة للإدارة السليمة للأعمال التجارية على مر السنين ، وبالتالي ، نحن متخصصون في توصيف العملاء وتوصيف احتياجات العمل وتحديد الأهداف والغايات. بعد ذلك ، نبني للعميل مجموعة كاملة من خطط العمل لأعماله</div>
                                    </div>
                                    <div class="author-info">
                                        <div class="wrapper-box">
                                            <h2>Hassan Sh. Massarwe</h2>
                                            <div class="designation">مؤسس</div>
                                            <div class="text">خبرة مثبتة وسنوات عديدة في المبيعات والعلاقات مع العملاء. حاصل على درجة علمية في الاقتصاد والمحاسبة ورأس المال البشري والبرمجيات. تتجلى قدرة حسن بشكل أساسي في قدرته على فهم رغبات العميل من اجتماع قصير دون إضاعة الوقت </div>
                                        </div>
                                        <div class="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                {/* History Section */}
                <section class="history-section">
                    <div class="auto-container">
                        <div class="sec-title text-center light">
                            <div class="sub-title">تاريخ</div>
                            <h2>تاريخنا   </h2>
                        </div>
                        <Tab.Container defaultActiveKey="first">
                            <Nav className="nav-tabs tab-btn-style-one" dir='rtl'>
                                <Nav.Item>
                                    <Nav.Link eventKey="first">2022</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">2018</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">2005</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">2000</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">1995</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first" className=" fadeInUp animated text-right ">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light text-right history-title">
                                                    <div class="sub-title " >Mass Portal</div>
                                                    <h2 dir='rtl'>اطلاق موقع ( Mass Portal)<br />كل الأدوات و الحلول في مكان واحد</h2>
                                                </div>
                                                <div className='text' dir='rtl'>
بيئة مالية مثالية للمحاسبين والممولين ومحاسبو الرواتب ومستشاري الضرائب والمحامين.

                                                </div>
                                                <div class="text" dir='rtl'>
                                                تقدم برمجيات ( Mass Portal ) الحل الرائد في البلد  للحسابات في مجال الضرائب والأجور والمالية
ويساعدك على توفير الوقت والمال الثمين.

                                                </div>
                                                <div class="link-btn"><Link to={{pathname:'http://mass-portal.com/'}} target='_blank' class="theme-btn btn-style-one"><span class="btn-title">للاستزادة</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className=" fadeInUp animated text-right">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light text-right history-title">
                                                    <div class="sub-title" >MATH Academy  </div>
                                                   
                                                    <h2>
                                                    في 2018 ، أنشأنا أول كلية هايتك عربية  للمحاسبة </h2><br></br>
                                                    <h2>MATH Academy</h2><br></br>
                                                    <h2>تعليم لاجل مستقبل العمل</h2>
                                                </div>
                                                <div class="text" dir='rtl'>برنامج Full-Stack Web Developer - ( MERN ) <br /><br />
                                                يقوم برنامج تطوير الويب الخاص بنا بتطوير مهندسي البرمجيات الذين هم خبراء في أنظمة 
                                                ( Front-End , Back-End ) . <br></br>احصل على التعليم الذي تحتاجه لمتابعة مهنة مرضية في مجال التكنولوجيا 
                                                ، وتصميم مواقع الويب ، مع إدارة وظائفه ، من كتابة التعليمات البرمجية إلى إدارة قواعد البيانات.
                                                    </div>
                                                    
                                                <div class="text" dir='rtl'> 
                                                    
                                                  (  UX Design )  برنامج  <br /><br />
                                                  تم تصميم برنامج تصميم تجربة المستخدم (UX) الخاص بنا للمهنيين العاملين الذين يحتاجون إلى المرونة للحصول على تعليم تقني. تعرف على كيفية جعل أفكارك تنبض بالحياة وتصبح مصمم UX مبدعًا.                                                    .
                                                    </div>
                                                <div class="link-btn"><Link to={{pathname:'https://math-ac2.web.app/'}} target='_blank' class="theme-btn btn-style-one"><span class="btn-title">Math Academy </span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className=" fadeInUp animated text-right">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light text-right history-title">
                                                    <div class="sub-title" dir='rtl'>الحصول على التكنولوجيا أولاً</div>
                                                    <h2 dir='rtl' className='text-right'>
                                                        استجابة لظروف التكنولوجيا المتغيرة ، ولمواصلة مساعدة عملائنا على النجاح
                                                    </h2>
                                                    
                                                    <h2 dir='rtl' className='text-right'>
                                                        حققنا استثمارات كبيرة في:
                                                    </h2>
                                                </div>
                                                <div className='text'>
                                                    <ul>
                                                        <li>عملائنا</li>
                                                        <li>أدواتنا</li>
                                                        <li>بنيتنا التحتية</li>
                                                        <li>تحالفات الموردين الاستراتيجية</li>
                                                        <li>التكنولوجيا والمنهجيات لدعم دوراتنا التدريبية في جميع أنحاء العالم</li>
                                                    </ul>

                                                </div>
                                                <div className='text'>
                                                نتيجة لذلك ، يمكننا تزويد عملائنا بحلول من مجموعتنا الكاملة من خدمات الاستشارات والتأمين ، من التقييم إلى التحويلات ، بما في ذلك إعادة تصميم العمليات وتنفيذ التكنولوجيا.
                                                </div>
                                                <div className='text'>
                                                منهجيتنا المتكاملة لأفضل الممارسات وإطار العمل العالمي لتقديم خدماتنا ، جنبًا إلى جنب مع الشبكة الداخلية العالمية الخاصة بنا للوصول العالمي إلى أدواتنا وتقنياتنا وقواعدنا المعرفية ، وتعزيز التآزر والتعاون عبر الحدود وتسهيل استخدام الموارد العالمية لتحسين خدمة عملائنا.

                                                </div>
                                                <div className='text'>
                                                لقد قمنا بتمكين نقل المعرفة والتعلم وتقديم خدمة العملاء إلكترونيًا وركزنا على خدمات تعزيز القيمة التي تعمل على تحسين فعالية وكفاءة إدارة المخاطر مع زيادة رضا أصحاب المصلحة.
                                                </div>
                                                {/* <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">للاستزادة</span></Link></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth" className=" fadeInUp animated text-right">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light text-right history-title">
                                                    <div class="sub-title" dir='rtl'> نمو  MHABC </div>
                                                    <h2>أثبتنا أنفسنا  في عام 2000 <br />بنتائج عظيمة </h2>
                                                </div>
                                                <div class="text" dir='rtl'>

                                                    في غضون خمس سنوات ، جمعت MHABC أعضاء في جميع أنحاء البلاد للتعامل مع متطلبات أعمالهم الدولية. يستطيع جميع الأعضاء تقديم خدمة شخصية تركز على فهم شامل لاحتياجات العملاء الخاصة بالمحاسبة.
                                                    يفخر كل عضو بمواكبة جميع المسائل المحاسبية بما في ذلك الضرائب ، ومن خلال الاتصال المنتظم بين جميع الاتحادات ، يتم الحفاظ على معايير التميز العالية للأعضاء في جميع أنحاء المنظمة.


                                                </div>
                                                {/* <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">للاستزادة</span></Link></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth" className=" fadeInUp animated text-right">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light text-right history-title">
                                                    <div class="sub-title" dir='rtl'>  تأسيس  MHABC</div>
                                                    <h2>بدء مهمة الحياة
                                                        في  1995
                                                        <br />
                                                        بمعرفة فقيرة وأحلام غنية</h2>
                                                </div>
                                                <div class="text" dir='rtl'>
                                                    أسس حسن شركة صغيرة في القدس تحمل العلامة التجارية MHABC
                                                    لخدمات المحاسبة والمراجعة حيث يقع في حب التدريب
                                                </div>
                                                {/* <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">للاستزادة  </span></Link></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>

                {/* Team Section */}

                {/* Services Section Four */}
                {/* <section class="services-section-four services-section-about">
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Features</div>
                            <h2>Core Features</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                    <h2>Audit Services</h2>
                                    <div class="text">Massarwe  performs efficient and effective audits to meet taxation and other reporting deadlines. Work of the representant is based on a clear understanding of the singular aspects of the client's business,. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-21.png')} alt="" /></div>
                                    <h2>Accounting Services</h2>
                                    <div class="text">Massarwe  has the experience, expertise and ability to offer a full range of high-quality accounting services. The representant does everything from maintenance of accounting books, records and systems to the preparation of periodic and annual management accounts, financial reports for different purposes and consolidated accounts </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                    <h2>Tax Services</h2>
                                    <div class="text">Massarwe  offers a range of tax services in IL, PNA & International laws, including standard activities (solving current tax issues, review of tax returns and monitoring current tax updates), and the emerging field of tax planning, which has become an essential business requirement when entering a major transaction, launching a new business or reorganizing an enterprise. The represenant's tax consultants offer timely advice and counsel to clients to effectively plan their corporate and personal tax.

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-22.png')} alt="" /></div>
                                    <h2>Coaching</h2>
                                    <div class="text">1. Executive Coaching <br />
                                        2. Senior Management Team Coaching <br />
                                        3. Team Leader Coaching <br />
                                        4. Team Coaching <br />
                                        5. New Manager Coaching <br />
                                        6. EQ Coaching <br />
                                        7. Career Transition Coaching
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>Business Consulting for Applications</h2>
                                    <div class="text">Massarwe  Consultants help many entrepreneurs turn their vision into a successful business. Building a business model for an application is a complex process that includes aspects of the economic market, but also building the company as a business, to make ends meet working with website builders, UI designers and other suppliers. Masaruh has advised over the years businesses small and large alike. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-23.png')} alt="" /></div>
                                    <h2>Business start-up advice:</h2>
                                    <div class="text">In the Business Startup Consulting Pathway, we will walk you through the process, help you plan, supplement the knowledge you lack in starting a business, and most importantly, teach you how to make it a growing and thriving business over the years. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* Sponsors Section */}

                {/* 
                <Brand1 />
 */}





                <Footer />
            </>
        )
    }
}
export default AboutAr;