import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Menu from './menu';
import MailTo from '../MailTo';
import PhoneTo from '../PhoneTo';
import MenuAr from './menu-ar';
const options = [
    { value: 'English', label: 'انجليزي' },
    { value: 'Arabic', label: 'عربي' }

]


class HeaderAr extends Component {

    state = {
        scrolled: false
    };

    componentDidMount() {
        var btn = document.querySelector('.mobile-nav-toggler');
        var closeBtn = document.querySelector('.close-btn');
        var body = document.getElementsByTagName('body')[0];

        function addFunc() {
            return body.classList.add("mobile-menu-visible");
        }
        function closeFunc() {
            return body.classList.remove("mobile-menu-visible");
        }

        btn.addEventListener('click', addFunc);
        closeBtn.addEventListener('click', closeFunc);

        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = event => {
        if (window.scrollY > 100) {
            this.setState({ scrolled: true });
        }
        else {
            this.setState({ scrolled: false });
        }
    };




    render() {
        const { scrolled } = this.state;
        return (
            <>
                <header class={scrolled ? "main-header fixed-header" : "main-header"}>
                    <div class="header-top">
                        <div class="auto-container" dir='rtl'>
                            <div class="inner">
                                <div class="top-left">


                                    <div class="logo-box">
                                        <div class="logo"><Link to={'/#'}><img src={require('../../assets/images/logo.png')} alt="" /></Link></div>
                                    </div>
                                </div>

                                <div class="top-middile">
                                    {/* <div class="language language-ar">
                                        <span>اللغة </span>
                                        <form action="#" class="language-switcher">
                                            <Select options={options} />
                                        </form>
                                    </div> */}
                                    <div class=" contact-info-ar contact-info" dir='rtl' >
                                        <div class="single-info" >
                                            <div class="text">رقم الهاتف </div>

                                            <div class="icon-box"><i class="flaticon-call-1"></i></div>
                                            <h4 dir='ltr'>
                                                <PhoneTo phone={'+972504316669'}>
                                                    +972 .2.623.1666
                                                </PhoneTo>
                                                </h4>

                                            {/* <h4 dir='ltr'><Link to={'/tel:+972504316669'}>+972 .2.623.1666</Link></h4> */}
                                        </div>
                                        <div>

                                        </div>
                                        {/* <div class="single-info">
                                            <div class="icon-box"><i class="flaticon-email-4"></i></div>
                                            <div class="text">البريد الالكتروني</div>
                                            <h4><Link to={{ pathname: 'hassan@massarwe.com' }}>info@massarwe.com</Link></h4>
                                        </div> */}
                                        <div className="single-info">
                                            <div class="icon-box"><i class="flaticon-email-4"></i></div>
                                            <div class="text">البريد الالكتروني</div>
                                            <h4> <MailTo email="hassan@massarwe.com" subject="" body="">
                                                info@massarwe.com
                                            </MailTo>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="top-right">
                                    <Link to={'/mass-projects-ar'} class="theme-btn btn-style-two" target='_blank'><span class="btn-title">Mass - Projects  </span></Link>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="header-upper">
                        <div class="auto-container">
                            <div class="inner-container">


                                <div class="nav-outer clearfix " >

                                    <div class="mobile-nav-toggler"><span class="icon fal fa-bars"></span></div>

                                    <nav class="main-menu-ar main-menu navbar-expand-md navbar-light" >
                                        <MenuAr />
                                    </nav>



                                    <ul class="social-links social-links-ar clearfix ">
                                        <li><a href="https://www.facebook.com/massarwe/" target='_blank'><span class="fab fa-facebook-f"></span> </a></li>
                                        {/* <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li> */}
                                        <li><a href={'https://www.linkedin.com/company/massarwe/about/'} target='_blank'><span class="fab fa-linkedin-in"></span></a></li>
                                        {/* <li><Link to={'/#'}><span class="fab fa-pinterest-p"></span></Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class={scrolled ? "sticky-header animated slideInDown" : "sticky-header"}>
                        <div class="auto-container clearfix">

                            <div class="logo pull-left">
                                <Link to={'/#'} title=""><img src={require('../../assets/images/sticky-logo.png')} alt="" title="" /></Link>
                            </div>

                            <div class="pull-right">
                                <nav class="main-menu main-menu-ar clearfix">
                                    <MenuAr />
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="mobile-menu" dir='rtl'>
                        <div class="menu-backdrop"></div>
                        <div class="close-btn"><span class="icon flaticon-cancel"></span></div>

                        <nav class="menu-box" dir='rtl'>
                            <div class="nav-logo"><Link to={'/#'}><img src="assets/images/logo.png" alt="" title="" /></Link></div>
                            <div class="menu-outer">
                                <MenuAr />
                            </div>
                            <div class="social-links">
                                <ul class="clearfix">
                                    {/* <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li> */}
                                    <li><Link to={{pathname:'/https://www.facebook.com/massarwe/'}} target='_blank'><span class="fab fa-facebook-square"></span></Link></li>
                                    {/* <li><Link to={'/#'}><span class="fab fa-pinterest-p"></span></Link></li> */}
                                    {/* <li><Link to={'/#'}><span class="fab fa-instagram"></span></Link></li> */}
                                    <li><Link to={{pathname:'https://www.youtube.com/channel/UClPrrdEVkAFqolTXfL-I_Kg/featured'}} target='_blank'><span class="fab fa-youtube"></span></Link></li>
                                </ul>
                            </div>
                        </nav>
                    </div>

                </header>

            </>
        )
    }
}
export default HeaderAr;


