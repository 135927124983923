import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Brand1 from '../element/brand1';


const aboutbg = require('./../../assets/images/background/image-11.jpg');



class About extends Component {


    render() {
        return (
            <>
                <Header />
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>


                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Regarding us</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Regarding us</li>
                        </ul>
                    </div>

                </section>


                {/* About Section Two */}
                <section class="about-section-two">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/resource/hassan.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">About Us</div>
                                        <h2>Developing strategies<br />Values. Results</h2>
                                        <div class="text">Massarwe Consulting established in 1995 to provide businesses from all fields and at all stages of life with assistance in creating the right business processes for the business. Our team has decades of experience and specializes in all types of business, high-tech, manufacturing industry, food, digital platforms, etc</div>
                                        <div class="text">Over the years we have learned that not every business owner possesses all the tools necessary for proper management of a business over the years, and therefore, we specialize in customer characterization, characterization of business needs, and setting goals and objectives. Next, we build for the client a complete set of work plans for his business.</div>
                                    </div>
                                    <div class="author-info">
                                        <div class="wrapper-box">
                                            <h2>Hassan Sh. Massarwe</h2>
                                            <div class="designation">Founder</div>
                                            <div class="text">Proven experience and many years in sales and customer relations. Holds a degree in economics, accounting, human capital and software. Hassan's ability is mainly expressed in his ability to understand the client's wishes from a short meeting without wasting time..</div>
                                        </div>
                                        <div class="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                {/* History Section */}
                <section class="history-section">
                    <div class="auto-container">
                        <div class="sec-title text-center light">
                            <div class="sub-title">History</div>
                            <h2>Our Story </h2>
                        </div>
                        <Tab.Container defaultActiveKey="first">
                            <Nav className="nav-tabs tab-btn-style-one">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">2022</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">2018</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">2005</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">2000</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">1995</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light">
                                                    <div class="sub-title">Mass Portal</div>
                                                    <h2>Launching the Mass Portal website 7/2022 <br />All tools and all solutions in one place.</h2>
                                                </div>
                                                <div class="text">An ideal financial environment for accountants, financiers, payroll accountants, tax advisors and lawyers.
"Mass Portal" software offers the country's leading solution for accounts in the field of taxes, wages and finance
</div>
                                                <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light">
                                                    <div class="sub-title">MATH Academy - Massarwe Academy for Technology and Hi-Tech</div>
                                                    <h2>In 2018 we established the 1st Arab  Hitech College of Accountancy MATH Academy <br/> An education for the future of work. </h2>
                                                </div>
                                                <div class="text">Full-Stack Web Developer -  (MERN) Program <br/><br/>
Our web development program develops software engineers who are experts in frontend and backend systems. Get the education you need to pursue a fulfilling career in tech designing a website’s aesthetics, while managing its functionality, from writing code to managing databases. <br/><br/>
UX Design Certificate Program  <br/><br/>
Our User Experience (UX) Design program is built for working professionals who need flexibility to get a tech education. Learn how to bring your ideas to life and become a creative UX designer.
.</div>
                                                <div class="link-btn"><Link to={'https://math-ac2.web.app/'} class="theme-btn btn-style-one"><span class="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light">
                                                    <div class="sub-title">Getting technology 1st</div>
                                                    <h2>In response to technology changing conditions, and to continue to help our clients to succeed <br />have made major investments in</h2>
                                                </div>
                                                <div class="text">· our clients <br />
· our people <br />
· our tools <br />, technology , and methodologies to support our practices around the world <br />
· Strategic vendor alliances <br />
· our infrastructure <br /><br />
As a result, we can provide our clients with solutions from our full range of consulting and assurance services, from assessment to transformation, involving process redesign and technology implementation. <br /> <br />
Our integrated best practices methodology and global framework for the delivery of our services, along with our global Intranet for global access to our tools, technologies, and knowledge bases, enhance cross-border synergies and collaboration and facilitates the use of global resources to better serve our clients.<br /><br />
We have e-enabled knowledge transfer, learning, and client service delivery and have focused on value creating services that improve risk management effectiveness and efficiency while increasing stakeholder satisfaction.
</div>
                                                <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light">
                                                    <div class="sub-title">MHABC Growth</div>
                                                    <h2>Just Proved Our Selves in 2000 <br />For Great Works.</h2>
                                                </div>
                                                <div class="text">In five years MHABC  gathered members throughout the country to deal with their international business requirements. All the members are able to offer a personal service, which focuses on having a thorough understanding of customers' particular Accounting needs. 
Each member prides himself in keeping up to date on all accounting matters including tax, and through regular contact between all Associations, members’ high standards of excellence are maintained throughout the Organization.
</div>
                                                <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title light">
                                                    <div class="sub-title">Establishing MHABC</div>
                                                    <h2>ٍStarting the mission of life  in  1995 <br />With poor Knowledge & rich dreams</h2>
                                                </div>
                                                <div class="text">Hassan established  a small firm  in jerusalem with the brand MHABC  
For accounting services and auditing since them he fall in love with coaching
</div>
                                                <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>

                {/* Team Section */}

                {/* Services Section Four */}
                <section class="services-section-four services-section-about">
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Features</div>
                            <h2>Core Features</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                    <h2>Audit Services</h2>
                                    <div class="text">Massarwe  performs efficient and effective audits to meet taxation and other reporting deadlines. Work of the representant is based on a clear understanding of the singular aspects of the client's business,. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-21.png')} alt="" /></div>
                                    <h2>Accounting Services</h2>
                                    <div class="text">Massarwe  has the experience, expertise and ability to offer a full range of high-quality accounting services. The representant does everything from maintenance of accounting books, records and systems to the preparation of periodic and annual management accounts, financial reports for different purposes and consolidated accounts </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                    <h2>Tax Services</h2>
                                    <div class="text">Massarwe  offers a range of tax services in IL, PNA & International laws, including standard activities (solving current tax issues, review of tax returns and monitoring current tax updates), and the emerging field of tax planning, which has become an essential business requirement when entering a major transaction, launching a new business or reorganizing an enterprise. The represenant's tax consultants offer timely advice and counsel to clients to effectively plan their corporate and personal tax.

</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-22.png')} alt="" /></div>
                                    <h2>Coaching</h2>
                                    <div class="text">1. Executive Coaching <br/>
2. Senior Management Team Coaching <br/>
3. Team Leader Coaching <br/>
4. Team Coaching <br/>
5. New Manager Coaching <br/>
6. EQ Coaching <br/>
7. Career Transition Coaching
 </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>Business Consulting for Applications</h2>
                                    <div class="text">Massarwe  Consultants help many entrepreneurs turn their vision into a successful business. Building a business model for an application is a complex process that includes aspects of the economic market, but also building the company as a business, to make ends meet working with website builders, UI designers and other suppliers. Masaruh has advised over the years businesses small and large alike. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-23.png')} alt="" /></div>
                                    <h2>Business start-up advice:</h2>
                                    <div class="text">In the Business Startup Consulting Pathway, we will walk you through the process, help you plan, supplement the knowledge you lack in starting a business, and most importantly, teach you how to make it a growing and thriving business over the years. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Sponsors Section */}

{/* 
                <Brand1 />
 */}





                <Footer />
            </>
        )
    }
}
export default About;