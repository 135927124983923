import React, { Component , useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
import Footer from '../layout/footer';
// import HomeSlider1 from './../element/home-slider1';
import HomeSlider1En from '../element/home-slider1-en';
import Welcomwe1 from './../element/welcome-1';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import MailTo from '../MailTo';
import PhoneTo from '../PhoneTo';
// import '../../assets/css/color.css';

import VideoPopup1 from './../element/video-popup1';
import PortfolioFilter1 from './../element/portfolio-filter1';

const teambg1 = require('./../../assets/images/background/image-3.jpg');
const newsbg1 = require('./../../assets/images/background/image-4.jpg');
const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');

export default function Index () {

    const notify = () => toast('شكرا لك  !, تم ارسال رسالتك بنجاح ', {
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // progressClassName: 'fancy-progress-bar',

        // progress: undefined,
    });


    // Form initial state
    const INITIAL_STATE = {
        name: "",
        email: "",
        subject: "",
    };
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState(INITIAL_STATE);
    const [subject, setSubject] = useState('')
    const form = useRef();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async e => {


        //    console.log("handler here")
        // =============== Emailjs Script ==================================
        emailjs.sendForm('service_ut4hief', 'template_qs7nk26', form.current, 'v_jHQ1-7ratTWPOWa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // =============== Emailjs Script ==================================
        notify();
        //    console.log(form.current)
        const url = '/thank-you-en';
        setTimeout(() => window.location.href = url, 1000);

    };

        return (
            <>
                <Header />


                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>


                {/* <Welcomwe1 /> */}

            <HomeSlider1En />
            

                {/* <!-- Welcome Setion --> */}
                <section class="welcome-section pt-0">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="row m-0">
                                {/* <!-- Welcome Block One --> */}
                                <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="flaticon-analysis"></span></div>
                                        <h2>Starting a new business with the right way</h2>
                                        <div class="text">The Massarwe team is the most suitable team for all the needs of your business. Our team will assist you in all the business issues involved in starting a new business, upgrading an existing business, looking for investors and acquisitions and business mergers. All assistance is provided through the creation of a business plan tailored to the business and its goals, building the right cash flow, market research, etc. </div>
                                        <div class="read-more"><Link to={'/#'}>Read More</Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="inner-box" style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}>
                                        <div class="icon-box"><span class="flaticon-tax"></span></div>
                                        <h2>Save Your Tax</h2>
                                        <div class="text"> There are lots of ways to reduce your tax bill legally, whether you're an employee or self-employed, a landlord, investor or pensioner.

We explain how simple checks could boost your take-home earnings with minimal effort, and how to take advantage of tax reliefs and government schemes.

 </div>
                                        <div class="read-more"><Link to={'/#'}>Read More</Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="flaticon-work-1"></span></div>
                                        <h2>Business Consulting for Applications</h2>
                                        <div class="text">
Massarwe Consultants help many entrepreneurs turn their vision into a successful business. Building a business model for an application is a complex process that includes aspects of the economic market, but also building the company as a business, to make ends meet working with website builders, UI designers and other suppliers. Massarwe has advised over the years businesses small and large alike.
 </div>
                                        <div class="read-more"><Link to={'/#'}>Read More</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* <!-- About Section --> */}
                <section class="about-section">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/resource/news-17.jpg')} alt="" />
                                    </div>
                                    <div class="image-two wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="caption">M.</div>
                                        <div class="image-outer"><img src={require('../../assets/images/resource/hassan.png')} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">About Us</div>
                                        <h2>Developing solutions <br />for the future</h2>
                                        <div class="text">The world is changing so rapidly that traditional ways of doing business are no longer sufficient .Now, more than ever, business need to act with the best knowledge possible and move forward with confidence in order to compete effectively.</div>
                                    </div>
                                    <div class="row text-center">
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><img src={require('../../assets/images/icons/icon-1.png')} alt="" /></div>
                                                <h5>Phone Number</h5>
                                                <h2>+972 50 431 6669</h2>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><img src={require('../../assets/images/icons/icon-2.png')} alt="" /></div>
                                                <h5>Email Address</h5>
                                                <h2>info@massarwe.com</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Services Section --> */}
                <section class="services-section" id='services-section'>
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column">
                                <div class="services-content">
                                    <div class="sec-title light">
                                        <div class="sub-title">Our Services</div>
                                        <h2>What Actually We <br />Do Here.</h2>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                        <h2>Business consulting for start-ups</h2>
                                        <div class="text">We build people, and create entrepreneurs.. </div>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                        <h2>UX Human-centred design</h2>
                                        <div class="text">Our approach to product design and delivery. </div>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                        <h2>HiTech Solutions & Services</h2>
                                        <div class="text">Massarwe  Consultants help many entrepreneurs turn their vision into a successful business. Building a business model for an application is a complex process that includes aspects of the economic market, but also building the company as a business, to make ends meet working with website builders, UI designers and other suppliers. Masaruh has advised over the years businesses small and large alike.. </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-column">
                                {/* <!-- Fact Counter --> */}
                                <div class="fact-counter">
                                    <div class="border-box">
                                        <div class="border_top"></div>
                                        <div class="border_bottom"></div>
                                        <div class="border_middile"></div>
                                    </div>
                                    <div class="row">

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-6.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={35}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span className='fact-counter-span'>Year</span>
                                                    </div>
                                                    <div class="counter-title">Accounting experience</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-7.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={25}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span className='fact-counter-span'>Year</span>
                                                    </div>
                                                    <div class="counter-title">Experience in Hi-Tech</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-8.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={12}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span className='fact-counter-span'>porject</span>
                                                    </div>
                                                    <div class="counter-title">community education</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-9.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={22}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        {/* <span>%</span> */}
                                                    </div>
                                                    <div class="counter-title">Fremium</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-10.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={1200}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        {/* <span>%</span> */}
                                                    </div>
                                                    <div class="counter-title">Consultant client</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-11.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={25}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span className='fact-counter-span'>Year</span>
                                                    </div>
                                                    <div class="counter-title">Vocational training</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="author-box" style={{ "backgroundImage": "url(assets/images/background/image-1.jpg)" }}>
                                    <div class="author-info">
                                        <div class="author-thumb"><img src={require('../../assets/images/resource/author-1.jpg')} alt="" /></div>
                                        <h2>Hassan Sh. Massarwe</h2>
                                        <div class="designation">Founder</div>
                                        <div class="text text-left">
                                        Proven experience and many years in sales and customer relations. Holds a degree in economics, accounting, human capital and software. Hassan's ability is mainly expressed in his ability to understand the client's wishes from a short meeting without wasting time.
                                        </div>
                                    </div>
                                    <div class="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* <!-- Gallery Section --> */}
                {/* <PortfolioFilter1 /> */}


                {/* <!-- Video Section --> */}
                <VideoPopup1 />



                {/* <!-- Why Choose Us Section --> */}
                <section class="why-chooseus-section">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">Why Choose Us</div>
                                        <h2>Why Should You <br />Choose Us ?</h2>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"><span>T H E  B E N E F I T S</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"><span>FinTech</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><span>Our Value</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="tabs-content">
                                            <Tab.Pane eventKey="first" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    <div class="image"><img src={require('../../assets/images/resource/image-4.jpg')} alt="" /></div>
                                                    <div class="text"><p>As a result of the network you have at your disposal, all the technical skills and expertise frequently required to advise on issues throughout the state, together with local business contacts and information on opportunities available. By use of the network we are in an excellent position to advise you. </p></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    <div class="image"><img src={require('../../assets/images/resource/image-4.jpg')} alt="" /></div>
                                                    <div class="text"><p>While fintech is a multifaceted concept, it’s possible to gain a strong understanding. FinTech simplifies financial transactions for consumers or businesses, making them more accessible and generally more affordable. It can also apply to companies and services utilizing AI, big data, and encrypted blockchain technology to facilitate highly secure transactions amongst an internal network.</p></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    <div class="image"><img src={require('../../assets/images/resource/image-4.jpg')} alt="" /></div>
                                                    <div class="text"><p>We adhere to six core values that not only define us as a worldwide organization but also serve as a guide for continued growth and professional excellence.
These values help us produce quality work for our clients; as well, as maintain enduring relationships within the Massarwe family of employees, and among our extensive alumni network. 
Leadership: We each take personal responsibility, have a clear direction, and lead and embrace change. We want people and clients to be proud to be associated with Massarwe Innovation: We relentlessly pursue improvements and dynamic change. We generate and share ideas willingly. 
Teamwork: We collaborate with colleagues to produce the best quality work for clients. We are responsive, effective, energetic, flexible, and focused on relationships. We openly share our knowledge with each other. 
Client-driven: We listen to our clients' needs and respond with energy, passion, speed, and agility. We use our skills and experience to provide the services our clients need. 
</p></div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>



                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one"><img src={require('../../assets/images/resource/image-6.jpg')} alt="" /></div>
                                    <div class="image-two"><img src={require('../../assets/images/resource/image-5.jpg')} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Team Section --> */}
                
                {/* <!-- Contact Section --> */}
                <section class="contact-section">
                    <div class="auto-container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sec-title">
                                    <div class="sub-title">Write Here</div>
                                    <h2>Get In Touch</h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div class="contact-form">
                                    <form  ref={form} onSubmit={handleSubmit(onSubmit)} id="contact-form">
                                        <div class="row clearfix">
                                            <div class="col-md-12 form-group">
                                            <label for="name">Enter your name</label>
                                            <input type="text" 
                                            name="name"
                                             id="name" 
                                            placeholder="Enter name here......" 
                                            onChange={(e) => setName(e.target.value)}
                                            {...register("name", { required: true })}
                                            />
                                             <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.name && 'Pleas Enter Your Name  '}
                                        </div>
                                            <i class="fas fa-user"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                            <label for="email">Enter your email</label>
                                            <input type="email" name="email" id="email"
                                             onChange={(e) => setEmail(e.target.value)}
                                             {...register("email", { required: true })}
                                             placeholder="Enter email here......" required=""/>
                                              <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.email && 'Pleas Check The Email   '}
                                        </div>
                                            <i class="fas fa-envelope"></i>
                                            </div>

                                            <div className="col-md-12 form-group ">
                                        <label > Enter your phone number  </label>
                                        <input type="number" name="phone" id="phone"
                                            onChange={(e) => setPhone(e.target.value)}
                                            {...register("phone", { required: true })}
                                            placeholder="   Enter phone number here.......... "
                                        />

                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.phone && 'Pleas check your phone number    '}
                                        </div>
                                        <i className="fas fa-phone"></i>
                                    </div>


                                            <div class="col-md-12 form-group">
                                            <label for="message">Enter your message</label>
                                            <textarea name="message" id="message" 
                                            placeholder="Enter message here......"
                                            onChange={(e) => setSubject(e.target.value)}
                                            {...register("message", { required: false })}
                                            ></textarea>
                                            <i class="fas fa-edit"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Get In Touch</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="sec-title">
                                    <div class="sub-title">Get Us Here</div>
                                    <h2>Contact Us</h2>
                                </div>
                                <div class="contact-info">
                                    <div class="border-box">
                                        <div class="border_top"></div>
                                        <div class="border_middile"></div>
                                    </div>
                                    <div class="row">
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-email-6"></span></div>
                                                <h3>Email Address</h3>
                                                <ul>
                                            <li>
                                                <MailTo email="info@massarwe.com" subject="" body="">
                                                    info@massarwe.com
                                                </MailTo>
                                            </li>
                                            <li>
                                                <MailTo email="mailto:info@webmail.com" subject="" body="">
                                                    hassan@massarwe.com
                                                </MailTo>
                                            </li>

                                        </ul>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-call-1"></span></div>
                                                <h3>Phone Number</h3>
                                                <ul>
                                            <li>
                                                <PhoneTo phone={'+97225476377'}>
                                                    +972 2 547 6377
                                                </PhoneTo>
                                            </li>
                                            <li>
                                            <PhoneTo phone={'++972(50)4316669'}>
                                            +972(50) 431 6669
                                                </PhoneTo>
                                            </li>
                                            {/* <li><Link to={'/tel:+97225476377'}>+972 2 547 6377</Link></li>
                                            <li><Link to={'/tel:+972(50)4316669'}>+972(50) 431 6669</Link></li> */}
                                        </ul>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-location"></span></div>
                                                <h3>Office Address</h3>
                                                <ul>
                                                    <li>Al-Qala #3 St,Pobox:11653 <br />Jerusalem Zip: 9111601</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-worldwide"></span></div>
                                                <h3>Our Projects:</h3>
                                                <ul>
                                                    <li><a href="https://math-ac2.web.app/" target="_blank" rel="noreferrer">Math-ac.com</a></li>
                                                    <li><a href="https://massportall.firebaseapp.com/" target="_blank" rel="noreferrer" >mass-portal.com</a></li>
                                                    <li><a href="https://mass-dev-cs.firebaseapp.com/" target="_blank" rel="noreferrer">MassDev</a></li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!-- News Section --> */}
                {/* <section class="news-section" id='news-section'>
                    <div class="sec-bg" style={{ backgroundImage: "url(" + newsbg1 + ")" }}></div>
                    <div class="auto-container">
                        <div class="sec-title text-center light">
                            <div class="sub-title">News</div>
                            <h2>News From Resource</h2>
                        </div>
                        <div class="row">
                            <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-1.jpg')} alt="" /></Link></div>
                                    <div class="lower-content">
                                        <div class="category">Business</div>
                                        <ul class="post-meta">
                                            <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i class="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br />business & make noise.</Link></h3>
                                        <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-2.jpg')} alt="" /></Link></div>
                                    <div class="lower-content">
                                        <div class="category">Business</div>
                                        <ul class="post-meta">
                                            <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i class="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>Business consultant is involved in the planning.</Link></h3>
                                        <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-3.jpg')} alt="" /></Link></div>
                                    <div class="lower-content">
                                        <div class="category">Business</div>
                                        <ul class="post-meta">
                                            <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i class="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>Business consultant is a professional who analyzes.</Link></h3>
                                        <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <!-- Award Section --> */}
                









                <Footer />
            </>
        )
    }
