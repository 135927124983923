import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import HeaderAr from '../layout/header-ar';
import Footer from '../layout/footer'
import ContactUs from '../element/ContactUs';
const aboutbg = require('./../../assets/images/background/image-11.jpg');
const addbg = require('./../../assets/images/background/image-15.jpg');


class BlogDestails1 extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <>
                <HeaderAr />



                {/* Search Popup */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Page Banner Section */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>ما هي استشارات الأعمال</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>الصفحة الرئيسية </Link></li>
                            <li class="active">مقالات </li>
                        </ul>
                    </div>

                </section>

                {/* Sidebar Page Container */}
                <div class="sidebar-page-container">
                    <div class="auto-container">
                        <div class="row text-right" dir='rtl'>
                            <div class="col-lg-8">
                                {/* News Block Two */}
                                <div class="news-block-two blog-single-post ">
                                    <div class="inner-box">
                                        <div class="lower-content">
                                            <div class="category">كيفية تقييم سعر الاستشارة</div>
                                            <ul class="post-meta">
                                                <li><Link to={'/#'}><i class="far fa-eye"></i>332 Views</Link></li>
                                                <li><Link to={'/#'}><i class="far fa-comments"></i>35 Comments</Link></li>
                                                <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                            </ul>
                                            <h2>ما هي استشارات الأعمال</h2>
                                            <div class="text">
                                                <p>
                                                    تزيد استشارات الأعمال من الكفاءة من خلال الاستخدام المناسب للميزانيات المتاحة لهم وتخلق زيادة في النشاط بشكل غير مباشر.
                                                    إنه أيضًا حل لأي عمل يسعى إلى التوسع والازدهار والقدرة على تحقيق إيرادات ستنمو بمثابرة وتراكم الخبرة في مجالات التسويق والمبيعات والعمل اليومي الداخلي والخارجي .
                                                    لا يتم مساعدة أصحاب الأعمال الذين يواجهون صعوبة فقط من خلال استشارات الأعمال ، ولكن أيضًا أولئك الذين يرغبون في النمو وزيادة الأرباح! لكل عمل احتياجات مختلفة اعتمادًا على الموقف الذي يجد نفسه فيه.
                                                    على سبيل المثال: تحسين المبيعات أو الترويج لمنتج جديد أو تنفيذ عملية جديدة في العمل.
                                                    تستخدم استشارات الأعمال كلاً من الشركات الصغيرة والكبيرة ، ليس فقط في المواقف الصعبة ولكن أيضًا لأغراض تحسين وتطوير وتوسيع الأعمال التجارية الحالية ، وتحقيق الإمكانات وتحقيق أقصى ربح.
                                                </p>
                                            </div>
                                            <div class="image"><Link to={'/#'}><img src={require('../../assets/images/blogs/blog-2.jpg')} alt="" /></Link></div>
                                            <div class="text-block-wrapper">
                                                <div class="text-block">
                                                    <h3>يقدم مستشار الأعمال ما يسمى بخدمات "الأعمال إلى الأعمال" (B2B) </h3>
                                                    <div class="text">
                                                        يقدم مستشار الأعمال ما يسمى بخدمات "الأعمال إلى الأعمال" (B2B) ، لأن عملائه هم رواد أعمال ومديرون وشركات ومنظمات أعمال مختلفة ، وليسوا عملاء نهائيين لنفس الكيانات التجارية. أصبح استخدام خدمات استشارات الأعمال اليوم أكثر شيوعًا من ذي قبل ولا يوجد عيب أو رد فعل عنيف ، فجميع أسواق الأعمال تنافسية للغاية ومعقدة ، لذا فإن منظورًا آخر أو تحليلًا خارجيًا ومهنيًا لبيئة الأعمال يمكن أن يساعد بالتأكيد في تحسين النتائج.
                                                        يمكن الحصول على خدمات مستشار الأعمال من مستشاري الأعمال المستقلين الذين يعملون حصريًا في هذا المجال ، وبعضهم مدربون معتمدون ، والبعض الآخر مستشارون تنظيميون ولديهم تدريب مهني / مالي / إداري آخر.
                                                        هناك أيضًا عدد من الشركات وشركات إدارة الحسابات التي تقدم أيضًا خدمات استشارات الأعمال للشركات الناشئة كجزء من مظلة خدمتهم ، مقابل رسوم إضافية بالطبع.
                                                    </div>
                                                </div>
                                                {/* <div class="text-block">
                                                    <h3>Setting the mood with incense</h3>
                                                    <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. </div>
                                                </div> */}
                                                {/* <div class="text-block">
                                                    <h3>Excepteur sint occaecat cupidatat</h3>
                                                    <ul class="list">
                                                        <li><i class="fas fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                                        <li><i class="fas fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                                        <li><i class="fas fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                                        <li><i class="fas fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                                        <li><i class="fas fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            {/* <blockquote>
                                                <h5>by Hetmayar</h5>
                                                <div class="text">Viral dreamcatcher keytar typewriter, aest hetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.</div>
                                                <div class="quote-icon"><span class="fa fa-quote-left"></span></div>
                                            </blockquote> */}
                                            {/* <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. </div>
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-8.jpg')} alt="" /></Link></div>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</div>
                                                </div>
                                            </div> 
                                            <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. 
                                             </div>
                                             */}
                                            <div class="post-share">
                                                {/* <div class="left-column">
                                                    <h4>Releted Tags</h4>
                                                    <ul class="tag-list clearfix">
                                                        <li><Link to={'/blog-details'}><span>Popular</span></Link></li>
                                                        <li><Link to={'/blog-details'}><span>desgin</span></Link></li>
                                                        <li><Link to={'/blog-details'}><span>ux</span></Link></li>
                                                    </ul>
                                                </div> */}
                                                {/* <div class="right-column">
                                                    <h4>Social Media</h4>
                                                    <ul class="social-links">
                                                        <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                                        <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                                        <li><Link to={'/#'}><span class="fab fa-behance"></span></Link></li>
                                                        <li><Link to={'/#'}><span class="fab fa-linkedin-in"></span></Link></li>
                                                        <li><Link to={'/#'}><span class="fab fa-youtube"></span></Link></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            {/* <div class="blog-post-pagination">
                                                <div class="wrapper-box">

                                                    <div class="prev-post">
                                                        <h5> Previous Post</h5>
                                                        <h4>Tips On Minimalist</h4>
                                                    </div>

                                                    <div class="page-view"><span class="fa fa-th"></span></div>

                                                    <div class="next-post">
                                                        <h5>Next Topic </h5>
                                                        <h4>Less Is More</h4>
                                                    </div>

                                                </div>
                                            </div> */}
            <div class="related-post">
                                                <div class="group-title">
                                                    <h3>مقالات أخرى </h3>
                                                </div>
                                                <div class="row">
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/blog-details4'}><img src={require('../../assets/images/resource/news-10.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/blog-details4'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details4'}>استشارات التحكم في الإنفاق.</Link></h3>
                                                                <div class="text">
                                                                    الفكرة في استشارات التحكم في الإنفاق هي التحفيز على الأشياء الصحيحة وزيادة الإيرادات.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/blog-details5'}><img src={require('../../assets/images/resource/news-11.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/blog-details5'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details5'}>استشارات لانشاء المصالح الجديدة.</Link></h3>
                                                                <div class="text">
                                                                    سنرافقك خلال العملية ، ونساعدك على التخطيط ، واستكمال المعرفة التي تفتقر إليها في بدء عمل تجاري  .</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/blog-details6'}><img src={require('../../assets/images/resource/news-30.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/blog-details6'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details6'}>حساب تقيم الشركات</Link></h3>
                                                                <div class="text">
                                                                    يتم إجراء التقييمات من قبل مستشارين تجاريين خبراء بارعين في طرق الحساب الاقتصادي المختلفة.

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/blog-details7'}><img src={require('../../assets/images/resource/news-31.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/blog-details7'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details7'}>استشارات الأعمال الدولية.</Link></h3>
                                                                <div class="text">
                                                                    من الأسهل على رجال الأعمال الوصول إلى وجهات بعيدة في العالم بفضل التطورات التكنولوجية والشركات الاستشارية.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="author-box-two">
                                                <div class="wrapper-area">
                                                    <div class="img-box">
                                                        <img src={require('../../assets/images/resource/author-3.jpg')} alt="Awesome " />
                                                    </div>
                                                    <div class="content">
                                                        <h5>Written by</h5>
                                                        <h3>Steve Anderson</h3>
                                                        <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation is enougn for today.</div>
                                                    </div>

                                                </div>
                                            </div> */}


                                        </div>
                                    </div>
                                </div>
                            </div>
                     
                           
                            <div class="col-lg-4">
                                <aside class="sidebar">

                                    {/* <!-- About Widget --> */}
                                    <div class="sidebar-widget about-me">
                                        <div className="sidebar-title " ><h3 className='text-center' >نشر بواسطة </h3></div>
                                        <div class="widget-content">
                                            <div class="image"><img src={require('../../assets/images/resource/author-1.jpg')} alt="" /></div>
                                            <h4>حسن مصاروة</h4>
                                            <div class="text">
                                                خبرة مثبتة وسنوات عديدة في مجال المبيعات والعلاقات مع العملاء. حاصل على إجازة في الاقتصاد , المحاسبة ,راس المال البشري والبرمجيات . يتم التعبير عن قدرة حسن بشكل أساسي في قدرته على فهم رغبات العميل من اجتماع قصير دون إضاعة الوقت.
                                            </div>
                                            <ul class="social-links">
                                                <li><Link to={{ pathname: "https://www.facebook.com/mathefuture/" }} target="_blank" ><span class="fab fa-facebook-f"></span></Link></li>
                                                {/* <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li> */}
                                                {/* <li><Link to={'/#'}><span class="fab fa-behance"></span></Link></li> */}
                                                <li><Link to={ {pathname: 'https://www.linkedin.com/company/math-massarwe-academy-of-technology-hi-tech/'}} target='_blank'><span class="fab fa-linkedin-in"></span></Link></li>
                                                <li><Link  to={ {pathname: 'https://www.youtube.com/channel/UClPrrdEVkAFqolTXfL-I_Kg/featured'}} target='_blank'><span class="fab fa-youtube"></span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* BLOGS WIDGET */}
                                    <div class="sidebar-widget ">
                                        <div className="sidebar-title " ><h3 className='text-center' >تواصل معنا   </h3></div>
                                        <div class="widget-content">
                                           <ContactUs />
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            {/* <div class="related-post">
                                                <div class="group-title">
                                                    <h3>مقالات أخرى </h3>
                                                </div>
                            <div class="row text-center">
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-10.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details'}>A series of iOS 7 inspire vector icons sense.</Link></h3>
                                                                <div class="text">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-11.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details'}>A series of iOS 7 inspire vector icons sense.</Link></h3>
                                                                <div class="text">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-11.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details'}>A series of iOS 7 inspire vector icons sense.</Link></h3>
                                                                <div class="text">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="news-block-five col-md-6">
                                                        <div class="inner-box">
                                                            <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-11.jpg')} alt="" /></Link></div>
                                                            <div class="lower-content">
                                                                <ul class="post-meta">
                                                                    <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                                                </ul>
                                                                <h3><Link to={'/blog-details'}>A series of iOS 7 inspire vector icons sense.</Link></h3>
                                                                <div class="text">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div></div> */}
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default BlogDestails1;