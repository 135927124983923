import React, { Component, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';

import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
// import Header6 from '../layout/header6';
import HeaderAr from '../layout/header-ar';
import Footer from '../layout/footer';
import Welcomwe1 from '../element/welcome-1';
import WelcomeAr from '../element/welcome-1-ar';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import 'react-toastify/dist/ReactToastify.css';
// import '../../assets/css/color.css';
import HomeSlider1 from './../element/home-slider1';
import VideoPopup1 from '../element/video-popup1';
import VideoPopup4 from '../element/video-popup4';
import PortfolioFilter1 from '../element/portfolio-filter1';
import PortfolioFilter1Ar from '../element/portfolio-filter1-ar';
// const teambg1 = require('./../../assets/images/background/image-3.jpg');
const newsbg1 = require('./../../assets/images/background/image-4.jpg');
// const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');


const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');
const authorbg1 = require('./../../assets/images/background/image-1.jpg');
// const videobg = require('./../../assets/images/background/image-2.jpg');
const teambg = require('./../../assets/images/background/image-3.jpg');
const newsbg = require('./../../assets/images/background/image-4.jpg');
export default function ContactUs() {

    const notify = () => toast('شكرا لك  !, تم ارسال رسالتك بنجاح ', {
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // progressClassName: 'fancy-progress-bar',

        // progress: undefined,
    });


    // Form initial state
    const INITIAL_STATE = {
        name: "",
        email: "",
        subject: "",
    };
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState(INITIAL_STATE);
    const [subject, setSubject] = useState('')
    const form = useRef();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async e => {


        //    console.log("handler here")
        // =============== Emailjs Script ==================================
        emailjs.sendForm('service_ut4hief', 'template_qs7nk26', form.current, 'v_jHQ1-7ratTWPOWa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // =============== Emailjs Script ==================================
        notify();
        //    console.log(form.current)
        const url = '/thank-you';
        setTimeout(() => window.location.href = url, 1000);

    };



    return(
        <>
        {/* <!-- Contact Section Two --> */}
      
        <section class="contact-section-two">
            <div class="auto-container">
                
               
                <div class="contact-form-area">
                   
                    {/* <!-- Contact Form--> */}
                    <div className="contact-form  text-right" >
                        <form ref={form} onSubmit={handleSubmit(onSubmit)} >
                            <div className="row clearfix">


                               
                                <div className="col-md-12 form-group frm-ar">
                                    <label for="name">اسمك  </label>
                                    <input type="text" name="name" id="name"
                                        className='text-right '
                                        onChange={(e) => setName(e.target.value)}
                                        {...register("name", { required: true })}
                                        // placeholder=" ..................... أدخل الاسم هنا "
                                         required=""
                                    />
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.name && 'الرجاء ادخال الاسم  '}
                                    </div>
                                    <i className="fas fa-user"></i>
                                </div>
                                <div className="col-md-12 form-group frm-ar">
                                    <label > رقم هاتفك  </label>
                                    <input type="number" name="phone" id="phone"
                                        className='text-right '
                                        onChange={(e) => setPhone(e.target.value)}
                                        {...register("phone", { required: true })}
                                        // placeholder="  ......... أدخل رقم الهاتف هنا "
                                    />

                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.phone && 'الرجاء التأكد من رقم الهاتف   '}
                                    </div>
                                    <i className="fas fa-phone"></i>
                                </div>

                                <div className="col-md-12 form-group frm-ar"  >
                                    <label for="email"> بريدك الالكتروني  </label>

                                    <input type="email" name="email" id="email"
                                        className='text-right '
                                        onChange={(e) => setEmail(e.target.value)}
                                        {...register("email", { required: true })}
                                        // placeholder="  ................ أدخل البريد  هنا "
                                    />
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.email && 'الرجاء التأكد من البريد الالكتروني   '}
                                    </div>
                                    <i className="fas fa-envelope "></i>
                                </div>
                                
                                <div className="col-md-12 form-group frm-ar">
                                    <label for="message">أدخل رسالتك  </label>
                                    <textarea name="message" id="message"
                                        className='text-right '
                                        onChange={(e) => setSubject(e.target.value)}
                                        {...register("message", { required: false })}
                                        // placeholder=" ..................... أدخل رسالتك هنا "
                                        >
                                    </textarea>

                                    <i className="fas fa-edit"></i>
                                </div>

                                <div className="col-md-12 form-group text-center">
                                    <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">اتصل بنا    </span></button>
                                </div>
                            </div>
                        </form>
                        <ToastContainer />

                    </div>

                </div>
            </div>
        </section>






       
    </>
    )
}