import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const aboutbg = require('./../../assets/images/background/image-11.jpg');

class Welcome1 extends Component {


    render() {
        return (
            <>
               
                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>reliable FinTech Consulting</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/'}>Welcome</Link></li>
                            <li class="active">to Massarwe</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}


                {/* <!-- Team Details --> */}
                {/* <section class="team-details">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image"><img src={require('../../assets/images/resource/hassan.png')} alt=""/></div>
                            </div>
                            <div class="col-lg-6 pl-lg-5">
                                <div class="sec-title">
                                    <div class="sub-title">CFO Full Stack Web Developer & UXer</div>
                                    <h2>Hassan Sh. Massarwe</h2>
                                </div>
                                <div class="text">The world is changing so rapidly that traditional ways of doing business are no longer sufficient .Now, more than ever, business need to act with the best knowledge possible and move forward with confidence in order to compete effectively..</div>
                                <div class="progress-box">
                                    <div class="bar">
                                        <div class="bar-inner count-bar" data-percent="90%"><div class="count-text">90%</div></div>
                                    </div>
                                    <h5>CEO</h5>
                                </div>
                                <div class="link-btn"><Link to={'/#'} class="theme-btn btn-style-two"><span class="btn-title">Contact With Me</span></Link></div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <!-- Experience Section --> */}
                

                {/* <!-- Case Studies --> */}
               





               
            </>
        )
    }
}
export default Welcome1;