import React, { Component , useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header'
import Footer from '../layout/footer'
import Brand1 from '../element/brand1'



const aboutbg = require('./../../assets/images/background/image-11.jpg');
const touchbg = require('./../../assets/images/background/image-8.jpg');



 export default function MassPEn() {


    const notify = () => toast('شكرا لك  !, تم ارسال رسالتك بنجاح ', {
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // progressClassName: 'fancy-progress-bar',

        // progress: undefined,
    });


    // Form initial state
    const INITIAL_STATE = {
        name: "",
        email: "",
        subject: "",
    };
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState(INITIAL_STATE);
    const [subject, setSubject] = useState('')
    const form = useRef();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async e => {


        //    console.log("handler here")
        // =============== Emailjs Script ==================================
        emailjs.sendForm('service_ut4hief', 'template_qs7nk26', form.current, 'v_jHQ1-7ratTWPOWa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // =============== Emailjs Script ==================================
        notify();
        //    console.log(form.current)
        const url = '/thank-you-en';
        setTimeout(() => window.location.href = url, 1000);

    };

 
        return (
            <>
                <Header />


                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Mass - Projects</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Mass - Projects</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}


                {/* <!-- Careers Section --> */}
                <section class="careers-section">
                    <div class="auto-container">
                        <div class="row " >
                            <div class="col-lg-4 col-md-6 career-block">
                                <div class="inner-box">
                                    <div class="time"><a  href='https://mass-portal.com/' target='_blank'>Accounting</a></div>
                                    <h3><a href='https://mass-portal.com/' target='_blank'>MassPortal</a></h3>
                                    <div class="text">
                                    MassPortal- a website for accountants in Jerusalem,
 includes financial calculators and an ERP system.

                                        </div>
                                        <ul class="info section-title">
                                   
                                   <li className='text-center section-title'>
                                    <strong className='sub-title'>
                                    <a href='https://mass-portal.com/' target='_blank'>mass-portal.com</a>
                                    </strong> 
                                    </li>
                               </ul>
                                    {/* <ul class="info">
                                        <li><strong>Location:</strong> Texus, USA</li>
                                        <li><strong>Sallary:</strong> $2000</li>
                                    </ul> */}
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 career-block">
                                <div class="inner-box">
                                    <div class="time"><a  href='https://mass-dev.com/' target='_blank'>Development</a></div>
                                    <h3><a href='https://mass-dev.com/' target='_blank'>massDev</a></h3>
                                    <div class="text">
                                    massDev - is a modern website design and marketing agency based in Jerusalem.

                                        </div>
                                        <ul class="info section-title">
                                   
                                   <li className='text-center section-title'><strong className='sub-title'><a href='https://mass-dev.com/' target='_blank'>mass-dev.com</a></strong> </li>
                               </ul>
                                    {/* <ul class="info">
                                        <li><strong>Location:</strong> Texus, USA</li>
                                        <li><strong>Sallary:</strong> $2000</li>
                                    </ul> */}
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 career-block">
                                <div class="inner-box">
                                    <div class="time"><a  href='https://math-ac2.web.app/' target='_blank'>Learning</a></div>
                                    <h3><a href='https://math-ac2.web.app/' target='_blank'>Math Academy </a></h3>
                                    <div class="text">
                                    MATH ACADEMY - the training arm of Massarwe Consulting,
  Training in the fields of ( Fintech ) and ( Hi-tech ).
                                        </div>

                                        <ul class="info section-title">
                                   
                                   <li className='text-center section-title'><strong className='sub-title'><a href='https://math-ac2.web.app/' target='_blank'>math-ac.com</a></strong> </li>
                               </ul>
                                  
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </section>

               

                {/* <!-- Services Section Two --> */}
                <section class="services-section-two services-section-careers">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column" style={{ backgroundImage: "url(" + touchbg + ")" }}>
                                <div class="sec-title light">
                                    <div class="sub-title">Get In Touch</div>
                                    <h2>Get Us Here Or <br/> Share Details</h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div class="contact-form">
                                    <form ref={form} onSubmit={handleSubmit(onSubmit)} id="contact-form">
                                        <div class="row clearfix">                                    
                                            <div class="col-md-12 form-group">
                                            <input type="text" 
                                            name="name"
                                             id="name" 
                                            placeholder="Enter name here......" 
                                            onChange={(e) => setName(e.target.value)}
                                            {...register("name", { required: true })}
                                            />
                                             <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.name && 'Pleas Enter Your Name  '}
                                        </div>
                                            <i class="fas fa-user"></i>
                                            </div>
                                            
                                            <div class="col-md-12 form-group">
                                            <input type="email" name="email" id="email"
                                             onChange={(e) => setEmail(e.target.value)}
                                             {...register("email", { required: true })}
                                             placeholder="Enter email here......" required=""/>
                                              <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.email && 'Pleas Check The Email   '}
                                        </div>
                                            <i class="fas fa-envelope"></i>
                                            </div>
                    
                                            <div className="col-md-12 form-group ">
                                      
                                        <input type="number" name="phone" id="phone"
                                            onChange={(e) => setPhone(e.target.value)}
                                            {...register("phone", { required: true })}
                                            placeholder="   Enter phone number here.......... "
                                        />

                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.phone && 'Pleas check your phone number    '}
                                        </div>
                                        <i className="fas fa-phone"></i>
                                    </div>

                                            <div class="col-md-12 form-group">
                                            <textarea name="message" id="message" 
                                            placeholder="Enter message here......"
                                            onChange={(e) => setSubject(e.target.value)}
                                            {...register("message", { required: false })}
                                            ></textarea>
                                            <i class="fas fa-edit"></i>
                                            </div>
                    
                                            <div class="col-md-12 form-group">
                                                <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Get In Touch</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="right-column">
                                <div class="services-content">
                                    <div class="sec-title">
                                        <div class="sub-title">Our Services</div>
                                        <h2>What Actually We <br/>Do Here.</h2>
                                    </div>
                                    <div class="icon-box">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt=""/></div>
                                        <h2>Audit Services</h2>
                                        <div class="text">
                                        Massarwe performs efficient and effective audits to meet taxation and other reporting deadlines. Work of the representant is based on a clear understanding of the singular aspects of the client's business.
                                         </div>
                                    </div>
                                    <div class="icon-box">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt=""/></div>
                                        <h2>Tax Services</h2>
                                        <div class="text">
                                        Massarwe offers a range of tax services in IL, PNA & International laws, including standard activities (solving current tax issues, review of tax returns and monitoring current tax updates), and the emerging field of tax planning, which has become an essential business requirement when entering a major transaction, launching a new business or reorganizing an enterprise. The represenant's tax consultants offer timely advice and counsel to clients to effectively plan their corporate and personal tax.
                                             </div>
                                    </div>
                                    <div class="icon-box">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt=""/></div>
                                        <h2>Business Consulting for Applications</h2>
                                        <div class="text">Massarwe Consultants help many entrepreneurs turn their vision into a successful business. Building a business model for an application is a complex process that includes aspects of the economic market, but also building the company as a business, to make ends meet working with website builders, UI designers and other suppliers. Masaruh has advised over the years businesses small and large alike. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Sponsors Section --> */}
                
                {/* <Brand1/> */}



                <Footer />
            </>
        )
    }
