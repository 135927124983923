import React from "react";
import ReactDOM from "react-dom";
import "../assets/css/style.css"
export default function PhoneTo({ phone, ...props }) {
  return (
    <a href={`tel:${phone}`}>
      {props.children}
    </a>
  );
}

