import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './pages/index';
import Index2 from './pages/index-2';
import Index3 from './pages/index-3';
import Index4 from './pages/index-4';
import IndexAr from './pages/index-ar';
import Onepage from './pages/onepage';
// import RtlHome from './pages/rtl-home';
import Error404 from './pages/404';
import About from './pages/about';
import AboutAr from './pages/about-ar';
import BlogDestails from './pages/blog-details'
import BlogDestails1 from './pages/blog-details-1';
import BlogDestails2 from './pages/blog-details-2';
import BlogDestails3 from './pages/blog-details-3';
import BlogDestails4 from './pages/blog-details-4';
import BlogDestails5 from './pages/blog-details-5';
import BlogDestails6 from './pages/blog-details-6';
import BlogDestails7 from './pages/blog-details-7';


import BlogGrid from './pages/blog-grid'
import BlogStandard from './pages/blog-standard'
import Careers from './pages/careers'
import MassPEn from './pages/mass-projects-en';
import MassPAr from './pages/mass-projects-ar';
import Checkout from './pages/checkout'
import Contact from './pages/contact'
import ContactAr from './pages/contact-ar';
import Faq from './pages/faq'
import Portfolio1 from './pages/portfolio-1'
import Portfolio2 from './pages/portfolio-2'
import PortfolioDetails from './pages/portfolio-details'
import ProductDetails from './pages/product-details'
import Services1 from './pages/services-1'
import Services2 from './pages/services-2'
import ServicesDetails from './pages/services-details'
import Shop from './pages/shop'
import ShoppingCart from './pages/shopping-cart'
import TeamDetails from './pages/team-details'
import Team from './pages/team';
import Testimonial1 from './element/testimonial1'
import BackToTop from './layout/backToTop'
import ThankYou from './pages/thank-you';
import ThankYouEn from './pages/thank-you-en';
class Router extends Component {
    render() {
        return (
            
            <BrowserRouter >
            {/* <BrowserRouter> */}
                <div class="page-wrapper">

                    <Switch>
                        <Route path='/' exact component={IndexAr} />
                          <Route path='/home-english' exact component={Index} />
                        {/* <Route path='/' exact component={IndexAr} />  */}
                        <Route path='/index-2' component={Index2} />
                        <Route path='/index-0' component={Index} />
                        <Route path='/index-3' component={Index3} />
                        <Route path='/index-4' component={Index4} />
                        <Route path='/onepage' component={Onepage} />
                        <Route path='/rtl-home' component={IndexAr} />
                        {/* <Route path='/rtl-old' component={RtlHome} /> */}
                        <Route path='/404' component={Error404} />
                        <Route path='/thank-you' component={ThankYou} />
                        <Route path='/thank-you-en' component={ThankYouEn} />
                        <Route path='/mass-projects-en' component={MassPEn} />
                        <Route path='/mass-projects-ar' component={MassPAr} />

                        <Route path='/about' component={About} />
                        <Route path='/about-ar' component={AboutAr} />
                        <Route path='/blog-details' component={BlogDestails} />
                        <Route path='/blog-details1' component={BlogDestails1} />
                        <Route path='/blog-details2' component={BlogDestails2} />
                        <Route path='/blog-details3' component={BlogDestails3} />
                        <Route path='/blog-details4' component={BlogDestails4} />
                        <Route path='/blog-details5' component={BlogDestails5} />
                        <Route path='/blog-details6' component={BlogDestails6} />
                        <Route path='/blog-details7' component={BlogDestails7} />

                        
                        <Route path='/blog-grid' component={BlogGrid} />
                        <Route path='/blog-standard' component={BlogStandard} />
                        <Route path='/careers' component={Careers} />
                        <Route path='/checkout' component={Checkout} />
                        <Route path='/contact' component={Contact} />
                        <Route path='/contact-ar' component={ContactAr} />
                        <Route path='/faq' component={Faq} />
                        <Route path='/portfolio-1' component={Portfolio1} />
                        <Route path='/portfolio-2' component={Portfolio2} />
                        <Route path='/portfolio-details' component={PortfolioDetails} />
                        <Route path='/product-details' component={ProductDetails} />
                        <Route path='/services-1' component={Services1} />
                        <Route path='/services-2' component={Services2} />
                        <Route path='/services-details' component={ServicesDetails} />
                        <Route path='/shop' component={Shop} />
                        <Route path='/shopping-cart' component={ShoppingCart} />
                        <Route path='/team-details' component={TeamDetails} />
                        <Route path='/team' component={Team} />
                        <Route path='/test' component={Testimonial1} />
                        <Route path='/*' component={Error404} />

                    </Switch>

                    <BackToTop />

                </div>
            </BrowserRouter>

        )
    }
}

export default Router;