import React, { Component, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import HeaderAr from '../layout/header-ar';
import Footer from '../layout/footer'
import GoogleMaps from "simple-react-google-maps";
import MailTo from '../MailTo';
import PhoneTo from '../PhoneTo';
const aboutbg = require('./../../assets/images/background/image-11.jpg');




export default function ContactAr() {


    const notify = () => toast('شكرا لك  !, تم ارسال رسالتك بنجاح ', {
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // progressClassName: 'fancy-progress-bar',

        // progress: undefined,
    });


    // Form initial state
    const INITIAL_STATE = {
        name: "",
        email: "",
        subject: "",
    };
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState(INITIAL_STATE);
    const [subject, setSubject] = useState('')
    const form = useRef();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async e => {


        //    console.log("handler here")
        // =============== Emailjs Script ==================================
        emailjs.sendForm('service_ut4hief', 'template_qs7nk26', form.current, 'v_jHQ1-7ratTWPOWa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // =============== Emailjs Script ==================================
        notify();
        //    console.log(form.current)
        const url = '/thank-you';
        setTimeout(() => window.location.href = url, 1000);

    };


    return (
        <>
     
            <HeaderAr />


            {/* <!--Search Popup--> */}
            {/* <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div> */}

            {/* <!-- Page Banner Section --> */}
            <section class="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                <div class="bottom-rotten-curve alternate"></div>

                <div class="auto-container">
                    <h1>اتصل بنا </h1>
                    <ul class="bread-crumb clearfix">
                        <li><Link to={'/#'}>الصفحة الرئيسية </Link></li>
                        <li class="active">اتصل بنا</li>
                    </ul>
                </div>

            </section>

            {/* <!-- Map Section --> */}
            {/* <!-- Map Section --> */}
            {/* <section class="map-section">
                    <div class="map-column">
                        <GoogleMaps
                            apiKey={"AIzaSyBQ5y0EF8dE6qwc03FcbXHJfXr4vEa7z54"}
                            style={{ height: "800px", width: "100%" }}
                            zoom={12}
                            center={{ lat: 314428.8, lng: 351215.2 }}
                            // markers={{ lat: 37.4224764, lng: -122.0842499 }} //optional
                        />
                    </div>
                </section> */}

            {/* <!-- Contact Section Two --> */}
            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            <section class="contact-section-two">
                <div class="auto-container">
                    <div class="contact-info-area">
                        <div class="contact-info">
                            <div class="row">
                                <div class="info-column col-lg-4">
                                    <div class="icon-box">
                                        <div class="icon"><span class="flaticon-email-6"></span></div>
                                        <h3>البريد الالكتروني</h3>
                                        <ul>
                                            <li>
                                                <MailTo email="info@massarwe.com" subject="" body="">
                                                    info@massarwe.com
                                                </MailTo>
                                            </li>
                                            <li>
                                                <MailTo email="mailto:info@webmail.com" subject="" body="">
                                                    hassan@massarwe.com
                                                </MailTo>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="info-column col-lg-4">
                                    <div class="icon-box">
                                        <div class="icon"><span class="flaticon-call-1"></span></div>
                                        <h3>رقم الهاتف</h3>
                                        <ul>
                                            <li>
                                                <PhoneTo phone={'+97225476377'}>
                                                    +972 2 547 6377
                                                </PhoneTo>
                                            </li>
                                            <li>
                                            <PhoneTo phone={'++972(50)4316669'}>
                                            +972(50) 431 6669
                                                </PhoneTo>
                                            </li>
                                            {/* <li><Link to={'/tel:+97225476377'}>+972 2 547 6377</Link></li>
                                            <li><Link to={'/tel:+972(50)4316669'}>+972(50) 431 6669</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div class="info-column col-lg-4">
                                    <div class="icon-box">
                                        <div class="icon"><span class="flaticon-location"></span></div>
                                        <h3>عنوان المكتب </h3>
                                        <ul>
                                            <li> شارع القلعة3 - ص.ب 11653 <br />القدس - الرمز البريدي  9111601</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-form-area">
                        <div class="sec-title text-center">
                            <div class="sub-title">اكتب هنا</div>
                            <h2>ابق على تواصل</h2>
                        </div>
                        {/* <!-- Contact Form--> */}
                        <div className="contact-form  text-right" >
                            <form ref={form} onSubmit={handleSubmit(onSubmit)} >
                                <div className="row clearfix">


                                    <div className="col-md-6 form-group frm-ar"  >
                                        <label for="email"> بريدك الالكتروني  </label>

                                        <input type="email" name="email" id="email"
                                            className='text-right '
                                            onChange={(e) => setEmail(e.target.value)}
                                            {...register("email", { required: true })}
                                            placeholder="  ................ أدخل البريد الالكتروني هنا "
                                        />
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.email && 'الرجاء التأكد من البريد الالكتروني   '}
                                        </div>
                                        <i className="fas fa-envelope "></i>
                                    </div>
                                    <div className="col-md-6 form-group frm-ar">
                                        <label for="name">اسمك  </label>
                                        <input type="text" name="name" id="name"
                                            className='text-right '
                                            onChange={(e) => setName(e.target.value)}
                                            {...register("name", { required: true })}
                                            placeholder=" ..................... أدخل الاسم هنا " required=""
                                        />
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.name && 'الرجاء ادخال الاسم  '}
                                        </div>
                                        <i className="fas fa-user"></i>
                                    </div>
                                    <div className="col-md-12 form-group frm-ar">
                                        <label > رقم هاتفك  </label>
                                        <input type="number" name="phone" id="phone"
                                            className='text-right '
                                            onChange={(e) => setPhone(e.target.value)}
                                            {...register("phone", { required: true })}
                                            placeholder="  ......... أدخل رقم الهاتف هنا "
                                        />

                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.phone && 'الرجاء التأكد من رقم الهاتف   '}
                                        </div>
                                        <i className="fas fa-phone"></i>
                                    </div>

                                    <div className="col-md-12 form-group frm-ar">
                                        <label for="message">أدخل رسالتك  </label>
                                        <textarea name="message" id="message"
                                            className='text-right '
                                            onChange={(e) => setSubject(e.target.value)}
                                            {...register("message", { required: false })}
                                            placeholder=" ..................... أدخل رسالتك هنا ">
                                        </textarea>

                                        <i className="fas fa-edit"></i>
                                    </div>

                                    <div className="col-md-12 form-group text-center">
                                        <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">اتصل بنا    </span></button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />

                        </div>

                    </div>
                </div>
            </section>






            <Footer />
        </>
    )

}
