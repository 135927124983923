import React, { Component, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';

import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
// import Header6 from '../layout/header6';
import HeaderAr from '../layout/header-ar';
import Footer from '../layout/footer';
import Welcomwe1 from '../element/welcome-1';
import WelcomeAr from '../element/welcome-1-ar';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import 'react-toastify/dist/ReactToastify.css';
// import '../../assets/css/color.css';
import HomeSlider1 from './../element/home-slider1';
import VideoPopup1 from '../element/video-popup1';
import VideoPopup4 from '../element/video-popup4';
import PortfolioFilter1 from '../element/portfolio-filter1';
import PortfolioFilter1Ar from '../element/portfolio-filter1-ar';
// const teambg1 = require('./../../assets/images/background/image-3.jpg');
const newsbg1 = require('./../../assets/images/background/image-4.jpg');
// const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');


const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');
const authorbg1 = require('./../../assets/images/background/image-1.jpg');
// const videobg = require('./../../assets/images/background/image-2.jpg');
const teambg = require('./../../assets/images/background/image-3.jpg');
const newsbg = require('./../../assets/images/background/image-4.jpg');

// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
// const MySwal = withReactContent(Swal)

export default function IndexAr() {





    const notify = () => toast('شكرا لك  !, تم ارسال رسالتك بنجاح ', {
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // progressClassName: 'fancy-progress-bar',

        // progress: undefined,
    });


    // Form initial state
    const INITIAL_STATE = {
        name: "",
        email: "",
        subject: "",
    };
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState(INITIAL_STATE);
    const [subject, setSubject] = useState('')
    const form = useRef();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async e => {


        //    console.log("handler here")
        // =============== Emailjs Script ==================================
        emailjs.sendForm('service_ut4hief', 'template_qs7nk26', form.current, 'v_jHQ1-7ratTWPOWa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // =============== Emailjs Script ==================================
        notify();
        //    console.log(form.current)
        const url = '/thank-you';
        setTimeout(() => window.location.href = url, 1000);

    };

    return (
        <React.Fragment>
            <HeaderAr />


            {/* <!--Search Popup--> */}
            <div id="search-popup" class="search-popup">
                <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                <div className="popup-inner">
                    <div className="overlay-layer"></div>
                    <div className="search-form">
                        <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <fieldset>
                                    <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                    <input type="submit" value="Search Now!" className="theme-btn" />
                                </fieldset>
                            </div>
                        </form>
                        <br />
                        <h3>Recent Search Keywords</h3>
                        <ul className="recent-searches">
                            <li><Link to={'/#'}>Finance</Link></li>
                            <li><Link to={'/#'}>Idea</Link></li>
                            <li><Link to={'/#'}>Service</Link></li>
                            <li><Link to={'/#'}>Growth</Link></li>
                            <li><Link to={'/#'}>Plan</Link></li>
                        </ul>
                    </div>

                </div>
            </div>


            {/* <WelcomeAr /> */}
            <HomeSlider1 />


            {/* <!-- Welcome Setion --> */}
            <section className="welcome-section pt-0">
                <div className="auto-container" dir='rtl'>
                    <div className="wrapper-box">
                        <div className="row m-0">
                            {/* <!-- Welcome Block One --> */}
                            <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="icon-box"><span className="flaticon-analysis"></span></div>
                                    <h2>استشارات الأعمال للتطبيقات  </h2>
                                    <div className="text">يساعد مستشارو مصاروه   العديد من رواد الأعمال على تحويل رؤيتهم إلى عمل ناجح. يعد بناء نموذج عمل لتطبيق ما عملية معقدة تشمل جوانب السوق الاقتصادية ، ولكن أيضًا بناء الشركة كشركة ، لتغطية نفقاتها في العمل مع بناة مواقع الويب ومصممي واجهة المستخدم والموردين الآخرين. نصحت مصاروه   على مر السنين الشركات الصغيرة والكبيرة على حد سواء. </div>
                                    <div className="read-more"><Link to={'/#'}>اقرأ أكثر  </Link></div>
                                </div>
                            </div>
                            {/* <!-- Welcome Block One --> */}
                            <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box" style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}>
                                    <div className="icon-box"><span className="flaticon-tax"></span></div>
                                    <h2 classNameName='box-title'>وفر دفع  الضرائب الخاصة بك  </h2>
                                    <div className="text">هناك العديد من الطرق لتقليل  مستحقات الضريبية بشكل قانوني ، سواء كنت موظفًا أو صاحب عمل أو مالك عقار أو مستثمر أو متقاعد.
                                        سوف نرشدك  بطرق بسيطة لكي  تعزز أرباحك بأقل جهد ، وكيفية الاستفادة من الإعفاءات الضريبية والمخططات الحكومية.
                                    </div>
                                    <div className="read-more"><Link to={'/#'}>اقرأ أكثر  </Link></div>
                                </div>
                            </div>
                            {/* <!-- Welcome Block One --> */}
                            <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="icon-box"><span className="flaticon-work-1"></span></div>
                                    <h2>فتح  مصلحة جديدة بالطريقة الصحيحة  </h2>
                                    <div className="text">فريق مصاروه  هو الفريق الأنسب لجميع احتياجات عملك. سيساعدك فريقنا في جميع قضايا الأعمال التي ينطوي عليها بدء عمل تجاري جديد ، وتطوير الأعمال الحالية ، والبحث عن المستثمرين وعمليات الاستحواذ ودمج الأعمال. يتم تقديم جميع المساعدة من خلال إنشاء خطة عمل مصممة خصيصًا للعمل وأهدافه ، وبناء التدفق النقدي المناسب ، وأبحاث السوق ، وما إلى ذلك. </div>
                                    <div className="read-more"><Link to={'/#'}>اقرأ أكثر  </Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/* <!-- About Section --> */}
            <section className="about-section">
                <div className="auto-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="image-wrapper">
                                <div className="image-one">
                                    <img src={require('../../assets/images/resource/image-2.jpg')} alt="" />
                                </div>
                                <div className="image-two wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="caption">M.</div>
                                    <div className="image-outer"><img className='' src={require('../../assets/images/resource/hassan.png')} alt="about-img" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="content-box">
                                <div className="sec-title text-right">
                                    <div className="sub-title " >معلومات عنا  </div>
                                    <h2 classNameName='about-title'>خطة الاستشارة مصممة خصيصًا لاحتياجاتك وقيودك وتطلعاتك  </h2>
                                    <div className="text">
                                    لمجموعة مصاروة الاستشارية  رؤى مميزة, الأكثر إلهامًا حول القضايا التي تشكل مستقبل الأعمال والمجتمع.
نقوم بتطوير خارطة الطريق الرقمية للصناعات الخاصة بك من خلال إستراتيجية 
أفضل لاكتساب البرامج 
واستعادة الحرية الاستراتيجية من خلال التحول الرقمي المستمر 
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="info-column col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><img src={require('../../assets/images/icons/icon-1.png')} alt="" /></div>
                                            <h5>رقم الهاتف  </h5>
                                            <h2>+972 50 431 6669</h2>
                                        </div>
                                    </div>
                                    <div className="info-column col-md-6 ">
                                        <div className="icon-box">
                                            <div className="icon"><img src={require('../../assets/images/icons/icon-2.png')} alt="" /></div>
                                            <h5> البريد الإلكتروني  </h5>
                                            <h2>info@massarwe.com</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Services Section --> */}
            <section className="services-section" id='services-section'>
                <div className="auto-container">
                    <div className="wrapper-box" dir='rtl'>
                        <div className="left-column text-right">
                            <div className="services-content" dir='rtl'>
                                <div className="sec-title light text-right">
                                    <div className="sub-title "  >خدماتنا  </div>
                                    <h2>في الواقع ما نفعله هنا </h2>
                                </div>
                                <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                    <h2>استشارات لانشاء المصالح الجديدة   </h2>
                                    <div className="text">نبني الناس ، ونخلق رواد الأعمال . </div>
                                </div>
                                <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                    <h2>استشارات لبناء التطبيقات  </h2>
                                    <div className="text">نهجنا في تصميم المنتج والتسليم. </div>
                                </div>
                                <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>حلول وخدمات Hi-Tech  </h2>
                                    <div className="text">يساعد مستشارون مصاروة العديد من رواد الأعمال على تحويل رؤيتهم إلى عمل تجاري ناجح. يعد بناء نموذج عمل لتطبيق ما عملية معقدة تتضمن جوانب من السوق الاقتصادي ، ولكن أيضًا بناء الشركة كعمل تجاري ، لتغطية نفقات العمل مع بناة مواقع الويب ومصممي واجهة المستخدم والموردين الآخرين. قدم مصاروه الاستشارات على مر السنين للشركات الصغيرة والكبيرة على حد سواء .. </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-column">
                            {/* <!-- Fact Counter --> */}
                            <div className="fact-counter">
                                <div className="border-box">
                                    <div className="border_top"></div>
                                    <div className="border_bottom"></div>
                                    <div className="border_middile"></div>
                                </div>
                                <div className="row">

                                    {/* <!--Column--> */}
                                    <div className="column counter-column col-md-6">
                                        <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-6.png')} alt="" /></div>
                                                <div className="count-outer count-box">
                                                    <CountUp
                                                        end={35}
                                                        redraw={true}
                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <VisibilitySensor
                                                                onChange={start}
                                                                delayedCall
                                                            >
                                                                <span className="count-text" ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                    <span className='fact-counter-span'>سنة</span>
                                                </div>
                                                <div className="counter-title"> خبرة في المحاسبة  </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Column--> */}
                                    <div className="column counter-column col-md-6">
                                        <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-7.png')} alt="" /></div>
                                                <div className="count-outer count-box">
                                                    <CountUp
                                                        end={25}
                                                        redraw={true}
                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <VisibilitySensor
                                                                onChange={start}
                                                                delayedCall
                                                            >
                                                                <span className="count-text" ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                    <span className='fact-counter-span'>سنة</span>
                                                </div>
                                                <div className="counter-title">خبرة في ال Hi-Tech  </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Column--> */}
                                    <div className="column counter-column col-md-6">
                                        <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-8.png')} alt="" /></div>
                                                <div className="count-outer count-box">
                                                    <CountUp
                                                        end={12}
                                                        redraw={true}
                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <VisibilitySensor
                                                                onChange={start}
                                                                delayedCall
                                                            >
                                                                <span className="count-text" ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                    <span className='fact-counter-span'>مشروع</span>
                                                </div>
                                                <div className="counter-title">تثقيف مجتمعي  </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Column--> */}
                                    <div className="column counter-column col-md-6">
                                        <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-9.png')} alt="" /></div>
                                                <div className="count-outer count-box">
                                                    <CountUp
                                                        end={22}
                                                        redraw={true}
                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <VisibilitySensor
                                                                onChange={start}
                                                                delayedCall
                                                            >
                                                                <span className="count-text" ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                    {/* <span>%</span> */}
                                                </div>
                                                <div className="counter-title">Fremium  </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Column--> */}
                                    <div className="column counter-column col-md-6">
                                        <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-10.png')} alt="" /></div>
                                                <div className="count-outer count-box">
                                                    <CountUp
                                                        end={1200}
                                                        redraw={true}
                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <VisibilitySensor
                                                                onChange={start}
                                                                delayedCall
                                                            >
                                                                <span className="count-text" ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                    {/* <span>%</span> */}
                                                </div>
                                                <div className="counter-title">زبون استشاري  </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Column--> */}
                                    <div className="column counter-column col-md-6">
                                        <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-11.png')} alt="" /></div>
                                                <div className="count-outer count-box">
                                                    <CountUp
                                                        end={25}
                                                        redraw={true}
                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <VisibilitySensor
                                                                onChange={start}
                                                                delayedCall
                                                            >
                                                                <span className="count-text" ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                    <span className='fact-counter-span'>سنة </span>
                                                </div>
                                                <div className="counter-title">تدريب مهني   </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="author-box" style={{ backgroundImage: "url(" + authorbg1 + ")" }}>
                                <div className="author-info">
                                    <div className="author-thumb text-center"><img src={require('../../assets/images/resource/author-1.jpg')} alt="" /></div>
                                    <h2 className='text-right'>حسن مصاروة  </h2>
                                    <div className="designation text-right">مؤسس   </div>
                                    <div className="text">خبرة مثبتة وسنوات عديدة في مجال المبيعات والعلاقات مع العملاء. حاصل على إجازة في الاقتصاد , المحاسبة ,راس المال البشري  والبرمجيات ،. يتم التعبير عن قدرة حسن  بشكل أساسي في قدرته على فهم رغبات العميل من اجتماع قصير دون إضاعة الوقت. </div>
                                </div>
                                <br></br>
                                {/* <div className="signature"><img src={require('../../assets/images/resource/signature.png')} alt=""/></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Gallery Section --> */}
            {/* <PortfolioFilter1Ar /> */}


            {/* <!-- Video Section --> */}
            <VideoPopup4 />



            {/* <!-- Why Choose Us Section --> */}
            <section className="why-chooseus-section text-right" dir='rtl'>
                <div className="auto-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content-box">
                                <div className="sec-title text-right">
                                    <div className="sub-title">لماذا اخترتنا  </div>
                                    <h2>لماذا يجب عليك إختيارنا ؟  </h2>
                                </div>
                                <Tab.Container defaultActiveKey="first">
                                    <Nav variant="pills" classNameName="nav nav-tabs tab-btn-style-one ">
                                        <Nav.Item className='tab-btn-style-one-ar'>
                                            <Nav.Link eventKey="first" ><span>رؤيتنا</span></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='tab-btn-style-one-ar'>
                                            <Nav.Link eventKey="second"><span>فوائد </span></Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                    <Tab.Content classNameName="tabs-content" dir='rtl'>
                                        <Tab.Pane eventKey="first" classNameName="fadeInUp animated">
                                            <div className="clearfix">
                                                {/* <div className="image "><img src={require('../../assets/images/resource/image-4.jpg')} alt="" /></div> */}
                                                <div className="text">
                                                    <p>
                                                        نلتزم بثلاث قيم أساسية لا تحددنا كمنظمة عالمية فحسب ، بل تعمل أيضًا كدليل للنمو المستمر والتميز المهني. تساعدنا هذه القيم في إنتاج أعمال عالية الجودة لعملائنا ؛ بالإضافة إلى الحفاظ على علاقات دائمة داخل عائلة مصاروة للموظفين ، وبين شبكة الخريجين الواسعة لدينا.
                                                    </p>
                                                    <p>
                                                    <span className='span-bold'> القيادة </span>: نتحمل كل منا المسؤولية الشخصية ، ولدينا اتجاه واضح ، ونقود التغيير ونتقبله. نريد من الناس والعملاء أن يفخروا بكونهم مرتبطين بابتكار مصاروي: نحن نتابع بلا كلل التحسينات والتغيير الديناميكي. نقوم بتوليد الأفكار ومشاركتها عن طيب خاطر.
                                                        </p>

                                                        <p>
                                                        <span className='span-bold'> العمل الجماعي </span>: نتعاون مع الزملاء لإنتاج عمل أفضل جودة للعملاء. نحن متجاوبون وفعالون وحيويون ومرنون ونركز على العلاقات. نحن نشارك معرفتنا علانية مع بعضنا البعض.
                                                        </p>
                                                        <p>
                                                        <span className='span-bold'> الاهتمام بالعميل </span> : نستمع إلى احتياجات عملائنا ونستجيب بالطاقة والعاطفة والسرعة وخفة الحركة. نستخدم مهاراتنا وخبراتنا لتقديم الخدمات التي يحتاجها عملاؤنا.
                                                        </p>
                                                        </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second" classNameName="fadeInUp animated">
                                            <div className="clearfix">
                                                {/* <div className="image"><img src={require('../../assets/images/resource/image-4.jpg')} alt="" /></div> */}
                                                <div className="text">
                                                    <p> 
                                                    كنتيجة للشبكة الموجودة تحت تصرفك ، فإن جميع المهارات والخبرات الفنية مطلوبة بشكل متكرر لتقديم المشورة بشأن القضايا في جميع أنحاء البلاد ، جنبًا إلى جنب مع جهات الاتصال التجارية المحلية والمعلومات حول الفرص المتاحة. باستخدام الشبكة ، نحن في وضع ممتاز لتقديم المشورة لك.
                                                    </p>
                                                    
                                                        </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third" classNameName="fadeInUp animated">
                                            <div className="clearfix">
                                                <div className="image"><img src={require('../../assets/images/resource/image-4.jpg')} alt="" /></div>
                                                <div className="text"><p>شركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p><p>شركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p></div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image-wrapper">
                                <div className="image-one"><img src={require('../../assets/images/about/about-1.jpg')} alt="" /></div>
                                <div className="image-two"><img src={require('../../assets/images/about/about-2.jpg')} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Team Section --> */}
            {/* <section className="team-section" style={{ backgroundImage: "url(" + teambg + ")" }}>
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <div className="sub-title">فريقنا  </div>
                        <h2> فريق القيادة </h2>
                    </div>
                    <div className="row" dir='rtl'>
                       
                        <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt="" /></div>
                                <div className="content">
                                    <div className="designation">مؤسس  </div>
                                    <h3>حسن مصاروة </h3>
                                    <div className="text">كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt="" /></div>
                                <div className="content">
                                    <div className="designation">المدير التنفيذي  </div>
                                    <h3>فيلافوس هـ. دي  </h3>
                                    <div className="text">تنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                </div>
                            </div>
                      
                        <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt="" /></div>
                                <div className="content">
                                    <div className="designation">مطور  </div>
                                    <h3>عبد الرحمن ج. </h3>
                                    <div className="text">تنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           */}

            {/* <!-- News Section --> */}
            <section className="news-section" id='news-section'>
                <div className="sec-bg" style={{ backgroundImage: "url(" + newsbg + ")" }}></div>
                <div className="auto-container">
                    <div className="sec-title text-center light">
                        <div className="sub-title">مقالاتنا   </div>
                        <h2>تعرف على طبيعة عملنا </h2>
                    </div>
                    <div className="row " dir='rtl'>
                        {/* <!-- News Block One --> */}
                        <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><Link to={'/blog-details1'}><img src={require('../../assets/images/blogs/blog-2.jpg')} alt="" /></Link></div>
                                <div className="lower-content">
                                    <div className="category">تقييم سعر الاستشارة  </div>
                                    <ul className="post-meta">
                                        <li><Link to={'/blog-details1'}><i className="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                        <li><Link to={'/#'}><i className="far fa-user"></i>حسن مصاروة   </Link></li>
                                    </ul>
                                    <h3 ><Link to={'/blog-details1'}>ما هي استشارات الأعمال </Link></h3>
                                    <div className="text">
                                        تزيد استشارات الأعمال من الكفاءة من خلال الاستخدام المناسب للميزانيات المتاحة لهم وتخلق زيادة في النشاط بشكل غير مباشر.

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- News Block One --> */}
                        <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><Link to={'/blog-details2'}><img src={require('../../assets/images/blogs/blog-3.jpg')} alt="" /></Link></div>
                                <div className="lower-content">
                                    <div className="category"> Hi-Tech   </div>
                                    <ul className="post-meta">
                                        <li><Link to={'/blog-details2'}><i className="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                        <li><Link to={'/#'}><i className="far fa-user"></i>حسن مصاروة </Link></li>
                                    </ul>
                                    <h3><Link to={'/blog-details2'}> استشارات لبناء التطبيقات</Link></h3>
                                    <div className="text">
                                        يتجه رواد الأعمال إلى عالم التطبيقات ويبدو حقًا أن مستقبل التجارة العالمية يكمن في الجدران غير المرئية للعوالم الرقمية.
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- News Block One --> */}
                        <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><Link to={'/blog-details3'}><img src={require('../../assets/images/blogs/blog-1.jpg')} alt="" /></Link></div>
                                <div className="lower-content">
                                    <div className="category">اعمال  </div>
                                    <ul className="post-meta">
                                        <li><Link to={'/blog-details3'}><i className="far fa-calendar-alt"></i>27th July 2022</Link></li>
                                        <li><Link to={'/#'}><i className="far fa-user"></i>حسن مصاروة  </Link></li>
                                    </ul>
                                    <h3><Link to={'/blog-details3'}>عرض أسعار استشارات الأعمال </Link></h3>
                                    <div class="text">
                                        يختلف سعر المنتجات المختلفة حسب الحجم والموقع والجودة والعلامة التجارية ولديها الكثير من المتغيرات الملموسة.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/* <!-- Award Section --> */}

            {/* <!-- Contact Section --> */}
            <section className="contact-section" >
                <div className="auto-container text-right">
                    <div className="row" >
                        <div className="col-lg-6">
                            <div className="sec-title">
                                <div className="sub-title">اكتب هنا </div>
                                <h2>ابق على تواصل  </h2>
                            </div>
                            {/* <!-- Contact Form--> */}
                            <div className="contact-form " >
                                <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group frm-ar">
                                            <label for="name">اسمك  </label>
                                            <input type="text" name="name" id="name"
                                                className='text-right' 
                                                onChange={(e) => setName(e.target.value)}
                                                {...register("name", { required: true })}
                                                placeholder="أدخل الاسم هنا " required=""
                                            />
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.name && 'الرجاء ادخال الاسم  '}
                                            </div>
                                            <i className="fas fa-user"></i>
                                        </div>

                                        <div className="col-md-12 form-group frm-ar">
                                            <label for="email"> بريدك الالكتروني  </label>
                                            <input type="email" name="email" id="email" className='text-right'
                                                onChange={(e) => setEmail(e.target.value)}
                                                {...register("email", { required: true })}
                                                placeholder="أدخل البريد الالكتروني هنا "
                                            />
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.email && 'الرجاء التأكد من البريد الالكتروني   '}
                                            </div>
                                            <i className="fas fa-envelope"></i>
                                        </div>

                                        <div className="col-md-12 form-group frm-ar">
                                            <label > رقم هاتفك  </label>
                                            <input type="number" name="phone" id="phone"
                                                className='text-right'
                                                onChange={(e) => setPhone(e.target.value)}
                                                {...register("phone", { required: true })}
                                                placeholder="أدخل رقم الهاتف هنا "
                                            />

                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.phone && 'الرجاء التأكد من رقم الهاتف   '}
                                            </div>
                                            <i className="fas fa-phone phone-icon" ></i>
                                        </div>

                                        <div className="col-md-12 form-group frm-ar">
                                            <label for="message">أدخل رسالتك  </label>
                                            <textarea name="message" id="message"
                                                className='text-right'
                                                onChange={(e) => setSubject(e.target.value)}
                                                {...register("message", { required: false })}
                                                placeholder="أدخل رسالتك هنا ">
                                            </textarea>

                                            <i className="fas fa-edit"></i>
                                        </div>

                                        <div className="col-md-12 form-group text-center">
                                            <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">اتصل بنا    </span></button>
                                        </div>
                                    </div>
                                </form>
                                <ToastContainer />

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sec-title">
                                <div className="sub-title">اتصل بنا هنا  </div>
                                <h2>اتصل بنا  </h2>
                            </div>
                            <div className="contact-info">
                                <div className="border-box">
                                    <div className="border_top"></div>
                                    <div className="border_middile"></div>
                                </div>
                                <div className="row">
                                    <div className="info-column col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><span className="flaticon-email-6"></span></div>
                                            <h3>عنوان البريد الإلكتروني</h3>
                                            <ul>
                                                <li><Link to={'/mailto:info@webmail.com'}>info@massarwe.com</Link></li>
                                                <li><Link to={'/mailto:jobs@exampleco.com'}>hassan@massarwe.com</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="info-column col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><span className="flaticon-call-1"></span></div>
                                            <h3>رقم الهاتف</h3>
                                            <ul>
                                                <li><Link to={'/tel:+97226231666'}>+972 2 623 1666</Link></li>
                                                <li><Link to={'/tel:+972(50)4316669'}>+972(50) 431669</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="info-column col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><span className="flaticon-location"></span></div>
                                            <h3>عنوان المكتب</h3>
                                            <ul>
                                                <li>القدس، القلعة 3 <br />القدس، القلعة 3</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="info-column col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><span className="flaticon-worldwide"></span></div>
                                            <h3>شبكة الاتصال  </h3>
                                            <ul>
                                                <li><a href="https://math-ac2.web.app/" target="_blank" rel="noreferrer">Math-ac.com</a></li>
                                                <li><a href="https://massportall.firebaseapp.com/" target="_blank" rel="noreferrer" >mass-portal.com</a></li>
                                                <li><a href="https://mass-dev-cs.firebaseapp.com/" target="_blank" rel="noreferrer">MassDev</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>









            <Footer />
        </React.Fragment>
    )
}

