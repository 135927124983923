import React, { Component, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Nav, Tab, } from 'react-bootstrap';
// import Header from '../layout/header'
import HeaderAr from '../layout/header-ar';
import Footer from '../layout/footer'
import Brand1 from '../element/brand1'


import {AiOutlineLink} from 'react-icons/ai';
const aboutbg = require('./../../assets/images/background/image-11.jpg');
const touchbg = require('./../../assets/images/background/image-8.jpg');



export default function MassPAr() {


    const notify = () => toast('شكرا لك  !, تم ارسال رسالتك بنجاح ', {
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // progressClassName: 'fancy-progress-bar',

        // progress: undefined,
    });


    // Form initial state
    const INITIAL_STATE = {
        name: "",
        email: "",
        subject: "",
    };
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState(INITIAL_STATE);
    const [subject, setSubject] = useState('')
    const form = useRef();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async e => {


        //    console.log("handler here")
        // =============== Emailjs Script ==================================
        emailjs.sendForm('service_ut4hief', 'template_qs7nk26', form.current, 'v_jHQ1-7ratTWPOWa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // =============== Emailjs Script ==================================
        notify();
        //    console.log(form.current)
        const url = '/thank-you';
        setTimeout(() => window.location.href = url, 1000);

    };


    return (
        <>
            <HeaderAr />


            {/* <!--Search Popup--> */}
            {/* <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div> */}

            {/* <!-- Page Banner Section --> */}
            <section class="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                <div class="bottom-rotten-curve alternate"></div>

                <div class="auto-container">
                    <h1>Mass - Projects</h1>
                    <ul class="bread-crumb clearfix">
                        <li><Link to={'/#'}>الصفحة الرئيسية</Link></li>
                        <li class="active">Mass - Projects</li>
                    </ul>
                </div>

            </section>
            {/* <!--End Banner Section --> */}


            {/* <!-- Careers Section --> */}
            <section class="careers-section text-right" dir='rtl'>
                <div class="auto-container">
                    <div class="row " >
                        <div class="col-lg-4 col-md-6 career-block">
                            <div class="inner-box">
                                <div class="time"><a href='https://mass-portal.com/' target='_blank'>محاسبة </a></div>
                                <h3><a href='https://mass-portal.com/' target='_blank'>MassPortal</a></h3>
                                <div class="text">
                                MassPortal- موقع الكتروني للمحاسبين في القدس,

تتضمن حاسبات مالية و نظام ERP لادارة شاملة للمحاسبين تتكيف مع العالم الجديد 

                                </div>

                                <ul class="info section-title">
                                   
                                        <li className='text-center section-title'><strong className='sub-title'><a href='https://mass-portal.com/' target='_blank'>mass-portal.com</a></strong> </li>
                                    </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 career-block">
                            <div class="inner-box">
                                <div class="time"><a href='https://mass-dev.com/' target='_blank'>هاي - تك</a></div>
                                <h3><a href='https://mass-dev.com/' target='_blank'>massDev</a></h3>
                                <div class="text">
                                    massDev -  هي وكالة متخصصة ورائدة فى مجال تصميم و تسويق المواقع الإلكترونية العصرية والتي تتمركز  في القدس
                              
                                </div>
                                <ul class="info section-title">
                                   
                                   <li className='text-center section-title'><strong className='sub-title'><a href='https://mass-dev.com/' target='_blank'>mass-dev.com</a></strong> </li>
                               </ul>
                                {/* <ul class="info">
                                        <li><strong>Location:</strong> Texus, USA</li>
                                        <li><strong>Sallary:</strong> $2000</li>
                                    </ul> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 career-block">
                            <div class="inner-box">
                                <div class="time"><a href='https://math-ac2.web.app/' target='_blank'>تعليم</a></div>
                                <h3><a href='https://math-ac2.web.app/' target='_blank'>Math Academy </a></h3>
                                <div class="text">
                                    MATH   ACADEMY -  هي الذراع  التدريبي لـ Massarwe Consulting ،
                                <br></br>
                                تقدم تدريب في مجالات  ال ( Hi-Tech ) و ال ( Fintech )

                                </div>
                                <ul class="info section-title">
                                   
                                   <li className='text-center section-title'><strong className='sub-title'><a href='https://math-ac2.web.app/' target='_blank'>math-ac.com</a></strong> </li>
                               </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            {/* <!-- Services Section Two --> */}
            <section class="services-section-two services-section-careers text-right">
                <div class="auto-container">
                    <div class="wrapper-box">
                        <div class="left-column" style={{ backgroundImage: "url(" + touchbg + ")" }} dir='rtl'>
                            <div class="sec-title light text-right">
                                <div class="sub-title" dir='rtl'>تواصل معنا </div>
                                <h2>ابق على تواصل </h2>
                            </div>
                            {/* <!-- Contact Form--> */}
                            <div class="contact-form">
                                <form ref={form} onSubmit={handleSubmit(onSubmit)} id="contact-form">
                                    <div class="row clearfix">
                                        <div class="col-md-12 form-group frm-ar">
                                            <input type="text"
                                                name="name"
                                                id="name"
                                                placeholder="أدخل الاسم هنا .........."
                                                onChange={(e) => setName(e.target.value)}
                                                {...register("name", { required: true })}
                                            />
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.name && 'الرجاء ادخال الاسم   '}
                                            </div>
                                            <i class="fas fa-user"></i>
                                        </div>

                                        <div class="col-md-12 form-group frm-ar">
                                            <input type="email" name="email" id="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                {...register("email", { required: true })}
                                                placeholder="أدخل البريد الالكتروني هنا ....... " required="" />
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.email && 'الرجاء التأكد من البريد الالكتروني    '}
                                            </div>
                                            <i class="fas fa-envelope"></i>
                                        </div>

                                        <div className="col-md-12 form-group frm-ar">

                                            <input type="number" name="phone" id="phone"
                                                onChange={(e) => setPhone(e.target.value)}
                                                {...register("phone", { required: true })}
                                                placeholder="   أدخل رقم الهاتف هنا.......... "
                                            />

                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.phone && 'الرجاء التأكد من رقم الهاتف     '}
                                            </div>
                                            <i className="fas fa-phone"></i>
                                        </div>

                                        <div class="col-md-12 form-group frm-ar">
                                            <textarea name="message" id="message"
                                                placeholder="أدخل الرسالة هنا ............."
                                                onChange={(e) => setSubject(e.target.value)}
                                                {...register("message", { required: false })}
                                            ></textarea>
                                            <i class="fas fa-edit"></i>
                                        </div>

                                        <div class="col-md-12 form-group text-center">
                                            <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">اتصل بنا </span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="right-column" dir='rtl'>
                            <div class="services-content">
                                <div class="sec-title text-right">
                                    <div class="sub-title" dir='rtl'>خدماتنا </div>
                                    <h2>في الواقع ما نفعله هنا.</h2>
                                </div>
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                    <h2>استشارات لانشاء المصالح الجديدة</h2>
                                    <div class="text">
                                        نبني الناس ، ونخلق رواد الأعمال .
                                    </div>
                                </div>
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                    <h2>استشارات لبناء التطبيقات</h2>
                                    <div class="text">
                                        نهجنا في تصميم المنتج والتسليم.
                                    </div>
                                </div>
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>حلول وخدمات Hi-Tech</h2>
                                    <div class="text">
                                        ساعد مستشارون مصاروة العديد من رواد الأعمال على تحويل رؤيتهم إلى عمل تجاري ناجح. يعد بناء نموذج عمل لتطبيق ما عملية معقدة تتضمن جوانب من السوق الاقتصادي ، ولكن أيضًا بناء الشركة كعمل تجاري ، لتغطية نفقات العمل مع بناة مواقع الويب ومصممي واجهة المستخدم والموردين الآخرين. قدم مصاروه الاستشارات على مر السنين للشركات الصغيرة والكبيرة على حد سواء .
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Sponsors Section --> */}

            {/* <Brand1/> */}



            <Footer />
        </>
    )
}
